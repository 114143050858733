import React, { useEffect, useState } from 'react';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Box, Card, Link, Typography } from '@mui/material';
import {
  TenantUserInvitation,
  TenantUserInvitationStatuses,
  TenantUserRoles,
  useRemoveTenantUserInvitationMutation,
  useResendTenantUserInvitationMutation,
  useTenantUserInvitationsLazyQuery,
} from '../../shared/types/generated';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { createTenantUserInvitationDialogStoreAction } from './tenant-actions';
import { CreateTenantUserInvitationDialog } from './components/CreateTenantUserInvitationDialog';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchTenantEvent } from './tenant-events';
import { useAuth } from '../auth/auth-hook';
import {
  getDateByTimezone,
  sanitizeTenantUserInvitationStatus,
  sanitizeTenantUserRole,
} from '../../shared/utils';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { useTableSort } from '../../shared/components/table/useTableSort';

export const TenantUserInvitations: React.FC = () => {
  const { tenant } = useEvent(fetchTenantEvent);
  const { tenant: currentTenant } = useAuth();
  const tenantId = tenant?.id || currentTenant?.id;
  const [count, setCount] = useState(0);
  const { sort, handleSort } = useTableSort();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callAction, { data, loading }] = useTenantUserInvitationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setCount(res?.tenantUserInvitations?.count || 0);
    },
  });

  const [removeInvitation] = useRemoveTenantUserInvitationMutation({
    onCompleted: () => {
      const tenantUsers = (data?.tenantUserInvitations?.items ||
        []) as TenantUserInvitation[];

      const page =
        tenantUsers.length > 1 ? pagination.page : pagination.page - 1;
      setPagination({
        ...pagination,
        page,
      });

      openSnackbarAction(
        'Invitación eliminada correctamente',
        TypeMessage.SUCCESS,
      );
      callAction({
        variables: {
          where: {
            sort,
            tenant: {
              id: tenantId as string,
            },
          },
          page: page - 1,
          pageSize: pagination.pageSize,
        },
      });
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const [resendInvitation] = useResendTenantUserInvitationMutation({
    onCompleted: () => {
      openSnackbarAction(
        'Invitación reenviada correctamente',
        TypeMessage.SUCCESS,
      );
      callAction({
        variables: {
          where: {
            sort,
            tenant: {
              id: tenantId as string,
            },
          },
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        },
      });
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  useEffect(() => {
    if (tenantId) {
      callAction({
        variables: {
          where: {
            sort,
            tenant: {
              id: tenantId as string,
            },
          },
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [callAction, pagination.page, pagination.pageSize, sort, tenantId]);

  const tenantUsers = (data?.tenantUserInvitations?.items ||
    []) as TenantUserInvitation[];

  const COLUMNS: TableColumnType<TenantUserInvitation>[] = [
    {
      columnName: 'EMAIL',
      columnValue: null,
      columnComponent: (item: TenantUserInvitation) => (
        <Link component="button" onClick={() => {}}>
          {item.email}
        </Link>
      ),
      sortableField: 'email',
    },
    {
      columnName: 'ROL',
      columnValue: (item: TenantUserInvitation) =>
        sanitizeTenantUserRole(item.role as TenantUserRoles),
      sortableField: 'role',
    },
    {
      columnName: 'STATUS',
      columnValue: (item: TenantUserInvitation) =>
        sanitizeTenantUserInvitationStatus(
          item.status as TenantUserInvitationStatuses,
        ),
      sortableField: 'status',
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item: TenantUserInvitation) =>
        getDateByTimezone(item.createdAt as string, item?.tenant?.timezone),
      sortableField: 'createdAt',
    },
  ];

  return (
    <>
      <Box
        marginBottom={1}
        paddingX={1}
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{ textAlign: { xs: 'center', md: 'start' } }}
          fontSize={{ xs: '18px', md: '22px' }}
          fontWeight={700}
        >
          {'Invitaciones'}
        </Typography>
        <ButtonDefault
          onClick={() => createTenantUserInvitationDialogStoreAction(true)}
          startIcon={
            <img src="/icon-add-user.svg" alt="clip" width={14} height={14} />
          }
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
            background: currentTenant?.identityColor ?? 'black',
          }}
        >
          INVITAR USUARIO
        </ButtonDefault>
      </Box>
      <Card
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}
      >
        <TableDefault
          count={count}
          items={tenantUsers}
          columns={COLUMNS}
          actions={[
            {
              actionName: 'Reenviar',
              isDisabled: (item) => item.status !== 'PENDING',
              onClick: (item) => {
                resendInvitation({
                  variables: {
                    input: {
                      id: item.id,
                    },
                  },
                });
              },
            },
            {
              actionName: 'Eliminar',
              isDisabled: (item) => item.status !== 'PENDING',
              onClick: (item) => {
                removeInvitation({
                  variables: {
                    input: {
                      id: item.id,
                    },
                  },
                });
              },
            },
          ]}
          page={pagination.page}
          pageSize={pagination.pageSize}
          loading={loading}
          messageLoading={'Cargando invitaciones...'}
          showPagination
          handleChangePage={(page) => {
            setPagination({
              ...pagination,
              page: page + 1,
            });
          }}
          handleSort={handleSort}
        />
        <CreateTenantUserInvitationDialog
          onSuccess={() =>
            callAction({
              variables: {
                where: {
                  tenant: {
                    id: tenantId as string,
                  },
                },
                page: pagination.page - 1,
                pageSize: pagination.pageSize,
              },
            })
          }
        />
      </Card>
    </>
  );
};
