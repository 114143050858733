export enum AuthStep {
  SIGN_UP = 'SIGN_UP',
  SIGN_IN = 'SIGN_IN',
  RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_NOT_FOUND = 'AUTH_FORGOT_PASSWORD_NOT_FOUND',
}

export type SignUpArgs = {
  name: string;
  email: string;
  password: string;
};

export type SignInArgs = {
  email: string;
  password: string;
};

export type AuthStore = {
  email: string;
  isLogin: boolean;
};
