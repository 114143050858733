import * as yup from 'yup';

export const authLoginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Correo electrónico no válido')
    .required('Correo electrónico es requerido'),
  password: yup.string().trim().required('Contraseña es requerida'),
});

export const authForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Correo electrónico no válido')
    .required('Correo electrónico es requerido'),
});
