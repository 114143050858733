export enum DashboardEvents {
  startSessionFacebook = 'start_session_facebook',
  startSession = 'start_session',
  swgStartSession = 'swg_start_session',
  facebookClicked = 'facebook_clicked',
  startSessionGoogle = 'start_session_google',
  wallCrashesPortal = 'wall_crashes_portal',
  startSessionApple = 'start_session_apple',
  authClicked = 'auth_clicked',
  wallCrashesContent = 'wall_crashes_content',
  swgRegisterUser = 'swg_register_user',
  pageVisit = 'page_visit',
  gmailClicked = 'gmail_clicked',
  registerUser = 'register_user',
  wallCrashesModal = 'wall_crashes_modal',
  analyticsAuthenticate = 'analytics_authenticate',
  appleClicked = 'apple_clicked',
}

export type WinnerNote = {
  count: number;
  url: string;
  id: string;
};

export type CountData = { count: number; date: string };


export type GenerateUserReport = {
  created_at?:string;
  date?:string;
  name?:string;
  email?:string;
  provider?:string;
  provider_id?:string;
  url_register?:string;
  gender?:string;
  sex?:string;
}