import { createStoreAction } from '@cobuildlab/react-simple-state';
import {
  changePlanStatusDialogStore,
  createPlanDialogStore,
  updatePlanDialogStore,
} from './plan-events';
import { Plan, TenantUser } from '../../shared/types/generated';
import { updateTenantUserDialogStore } from '../tenant/tenant-events';

export const createPlanDialogStoreAction = createStoreAction(
  createPlanDialogStore,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);

export const changePlanStatusStoreAction = createStoreAction(
  changePlanStatusDialogStore,
  (prev, isOpen: boolean, plan: Plan | undefined) => ({
    ...prev,
    isOpen,
    plan,
  }),
);

export const updatePlanDialogStoreAction = createStoreAction(
  updatePlanDialogStore,
  (prev, isOpen: boolean, plan: Plan | undefined) => ({
    ...prev,
    isOpen,
    plan,
  }),
);
