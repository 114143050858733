import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useStore } from '@cobuildlab/react-simple-state';
import { useGetSwgTapLazyQuery } from '../../../shared/types/generated';
import { generateDateFilter } from '../../../shared/utils';
import { useAuth } from '../../auth/auth-hook';
import { changeDashboardDateFilterStore } from '../dashboard-events';
import { DashboardCardItem } from './DashboardCardItem';
import {
  getDateFilterSectionText,
  getDateFilterTitleText,
} from '../dashboard-utils';

export const DashboardGoogleContainer: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [callAction, { data, loading }] = useGetSwgTapLazyQuery({});

  useEffect(() => {
    callAction({
      variables: {
        where: {
          from,
          to,
          tenantId: tenant?.id,
        },
      },
    });
  }, [from, to, tenant, callAction]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DashboardCardItem
          loading={loading}
          title="Home"
          content={data?.getSwgTap?.total_swg_in_home || 0}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardCardItem
          loading={loading}
          title={
            <Typography textTransform="capitalize">
              {getDateFilterSectionText(from, to, filter)}
            </Typography>
          }
          content={data?.getSwgTap?.total_swg_in_other_section || 0}
        />
      </Grid>
    </Grid>
  );
};
