import React from 'react';
import { Box, Typography } from '@mui/material';


export const MagazinePDFPageError: React.FC = () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' height={445}>
      <Typography textAlign='center'>Tenemos problemas <br /> para visualizar el pdf</Typography>
    </Box>
  );
};