import { openSnackbarAction } from '../components/snackbar/snackbar-actions';
import { TypeMessage } from '../components/snackbar/snackbar-types';

/**
 * @param formState - Form error state.
 * @param notShowSnackbar - If the function should show the snackbar.
 * @returns String message.
 */
export function handleFormErrors(
  formState: Record<string, unknown>,
  notShowSnackbar?: boolean,
): string {
  const values = Object.values(formState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message = values.reduce((acc: string, value: any) => {
    let displayMessage = '';
    if (value && !Array.isArray(value) && value.message) {
      displayMessage = value.message;
    }

    if (!value?.message && typeof value === 'object') {
      displayMessage = handleFormErrors(value, true);
    }

    return acc.length <= 0
      ? displayMessage ?? ''
      : `${acc}, \n ${displayMessage.toLowerCase()}`;
    return acc;
  }, '') as unknown as string;

  if (!notShowSnackbar) {
    openSnackbarAction(message, TypeMessage.ERROR);
  }
  return message;
}
