import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { CardSection } from '../../shared/components/card/CardSection';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { CreateMagazineDialog } from './components/CreateMagazineDialog';
import { createMagazineDialogStoreAction, fetchMagazinesAction } from './magazine-actions';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Magazine } from './magazine-types';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchMagazinesEvent } from './magazine-events';
import { getFrequency, getStatus } from './magazine-utils';
import { useNavigate } from 'react-router-dom';


export const MagazineList: React.FC = () => {
  const { magazines, count } = useEvent(fetchMagazinesEvent);
  const [callback, loading] = useCallAction(fetchMagazinesAction);
  const navigate = useNavigate();
  const [where] = useState({
    title: '',
  });
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });


  useEffect(() => {
    callback(where);
  }, [where.title, callback, where]);


  const COLUMNS: TableColumnType<Magazine>[] = [
    {
      columnName: 'NOMBRE',
      columnValue: null,
      columnComponent: (item: Magazine) => (
        <Link component='button' onClick={() => {
        }}>
          {item.title || ''}
        </Link>
      ),
    },
    {
      columnName: 'CATEGORIA',
      columnValue: null,
      columnComponent: (item: Magazine) => (
        <Typography>{item.category?.name || '-'}</Typography>
      ),
    },
    {
      columnName: 'PERIODO',
      columnValue: null,
      columnComponent: (item: Magazine) => (
        <Typography>{getFrequency(item.frequency)}</Typography>
      ),
    },
    {
      columnName: 'ESTADO',
      columnValue: null,
      columnComponent: (item: Magazine) => (
        <Typography>{getStatus(item.status)}</Typography>
      ),
    },
  ];
  return (
    <Box>
      <CardSection
        title={<Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
          Revista digital
        </Typography>}
        actions={
          <Box pb={2}>
            <ButtonDefault
              style={{
                width: 195,
                height: 40,
                textTransform: 'inherit',
                fontWeight: 700,
              }}
              onClick={() => createMagazineDialogStoreAction(true)}
            >
              CREAR
            </ButtonDefault>
          </Box>
        }>

        <TableDefault
          items={magazines}
          count={count}
          columns={COLUMNS}
          page={pagination.page}
          pageSize={pagination.pageSize}
          loading={loading}
          messageLoading={'Cargando revistas...'}
          handleChangePage={(page) => {


          }}
          showPagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          handleChangeRowPerPage={(e) => {
            setPagination({
              ...pagination,
              pageSize: parseInt(e.target.value, 10),
              page: 1,
            });
          }}
          actions={[
            {
              actionName: 'Ver detalles',
              onClick: (item) => navigate(`/magazine/details/${item.id}`),
            },
          ]}
        />

      </CardSection>
      <CreateMagazineDialog />
    </Box>
  );
};