import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment/moment';
import 'moment/locale/es';
import { Comment, ReplyComment } from '../comment-types';
import { CommentStatus } from './CommentStatus';
import { CommentFilter } from './CommentFilter';

moment.locale('es');

const useStyles = makeStyles({
  cardHeader: {
    '& > .MuiCardHeader-action': {
      marginTop: 0,
      marginRight: 0,
    },
    '& > .MuiCardHeader-avatar': {
      outline: '1px solid #eaecf1',
      borderRadius: '50%',
    },
    alignItems: 'center',
  },
  reply: {
    cursor: 'pointer',
  },
});

type CommentItemProps = {
  comment: Comment | ReplyComment;
  onRefetch: () => void;
};
export const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  onRefetch,
}) => {
  const classes = useStyles();
  const { user } = comment;

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Box display="flex">
            <Typography variant="body1" fontWeight={700} mr={1} fontSize={14}>
              {user?.name}
            </Typography>
            <Typography variant="body1" fontSize={14}>
              {moment(comment.created_at as string).fromNow()}
            </Typography>
          </Box>
        }
        avatar={
          <Avatar sx={{ width: 40, height: 40 }} src={user?.avatar as string} />
        }
        action={
          <Box display="flex" alignItems="center">
            <CommentStatus comment={comment} />
            <CommentFilter comment={comment} onSuccess={onRefetch} />
          </Box>
        }
      />
      <CardContent>
        <div
          dangerouslySetInnerHTML={{ __html: comment.message as string }}
        ></div>
      </CardContent>
    </Card>
  );
};
