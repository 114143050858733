import { createContext } from 'react';
import { Tenant, User } from '../../shared/types/generated';
import { universalCookies } from '../../shared/cookies';
import { USER_COOKIE } from '../../shared/constants';

export type DefaultValuesContextType = {
  isAuthenticated: boolean;
  loading: boolean;
  user: User | undefined | null;
  tenant: Tenant | undefined | null;
  refetch: () => void;
};

const defaultValues = {
  isAuthenticated: !!universalCookies.get(USER_COOKIE),
  loading: true,
  user: undefined,
  tenant: undefined,
  refetch: () => {},
};
export const AuthContext =
  createContext<DefaultValuesContextType>(defaultValues);

export const { Provider } = AuthContext;
