import React, { useRef, useState } from 'react';
import { Box, Grid, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../shared/components/inputs/SelectDefault';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchTenantEvent } from './tenant-events';
import { UpdateTenantInput } from '../../shared/types/generated';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { fetchTenant, updateTenantAction } from './tenant-actions';
import { useParams } from 'react-router-dom';
import { MuiColorInput } from 'mui-color-input';
import { DEFAULT_TIMEZONE, TIMEZONES_LIST } from '../../shared/constants';
import { MuiChipsInput } from 'mui-chips-input';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 300,
    height: 300,
    borderRadius: '50%',
    margin: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
  },
  muiChipInput: {
    '&> .MuiInputBase-root': {
      alignItems: 'center',
    },
  },
}));

export const TenantDetails: React.FC = () => {
  const classes = useStyles();
  const { tenant } = useEvent(fetchTenantEvent);
  const [form, setForm] = useState<UpdateTenantInput | undefined | null>(
    tenant,
  );
  const [callTenant] = useCallAction(fetchTenant);
  const { id } = useParams<{ id: string }>();

  const refFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [updateTenant, loading] = useCallAction(updateTenantAction, {
    onCompleted: () => {
      openSnackbarAction(
        'Se ha actualizado el tenant exitosamente.',
        TypeMessage.SUCCESS,
      );
      callTenant(id as string);
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar el tenant',
        TypeMessage.ERROR,
      );
    },
  });

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof UpdateTenantInput]: value,
    });
  };

  return (
    <Box>
      <Grid container>
        <input
          type="file"
          hidden
          ref={refFile}
          onChange={(event) => {
            setFile(
              event.target.files && event.target.files.length
                ? event.target.files[0]
                : null,
            );
          }}
        />
        <Grid item xs={6}>
          <Avatar
            onClick={() => refFile.current?.click()}
            className={classes.avatar}
            src={file ? URL.createObjectURL(file) : (tenant?.logo as string)}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputDefault
                variant="outlined"
                labelName="NOMBRE"
                value={form?.name}
                name="name"
                onChangeData={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputDefault
                variant="outlined"
                labelName="WEBHOOK"
                value={form?.webhook}
                name="webhook"
                onChangeData={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectDefault
                variant="outlined"
                labelName="TIMEZONES"
                value={form?.timezone || DEFAULT_TIMEZONE}
                name="timezone"
                options={TIMEZONES_LIST}
                onChangeData={onChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>URL PERMITIDAS</Typography>
              <MuiChipsInput
                className={classes.muiChipInput}
                size="small"
                fullWidth
                placeholder="Escribe y presiona ENTER"
                inputProps={{
                  style: {
                    height: 30,
                    alignItems: 'center!important',
                  },
                }}
                value={(form?.allowedUrls || []) as []}
                onChange={(values) => {
                  setForm({
                    ...form,
                    allowedUrls: values,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>COLOR DE IDENTIDAD</Typography>
              <MuiColorInput
                inputProps={{
                  style: {
                    height: 30,
                  },
                }}
                fullWidth
                size="small"
                value={form?.identityColor || '#ffffff'}
                format="hex"
                onChange={(value) => {
                  setForm({
                    ...form,
                    identityColor: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="end">
                <ButtonDefault
                  isLoading={loading}
                  onClick={() => {
                    updateTenant({
                      input: {
                        id: tenant?.id as string,
                        name: form?.name as string,
                        webhook: form?.webhook as string,
                        identityColor: form?.identityColor as string,
                        timezone: form?.timezone as string,
                        allowedUrls: form?.allowedUrls as string[],
                        plan: {
                          id: form?.plan?.id as string,
                        },
                        file: file,
                      },
                    });
                  }}
                >
                  ACTUALIZAR
                </ButtonDefault>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
