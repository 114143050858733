import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStepAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { PRIMARY_COLOR } from '../../../shared/css/theme';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const StyledTypography = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold!important',
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '19px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FFC7A4!important',
  },
  width: 200,
});

export const AuthForgotPasswordNotFound: React.FC = () => {
  return (
    <Box
      sx={{
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={10} style={{ textAlign: 'center' }}>
          <Box my={4}>
            <StyledTypography variant="subtitle1">
              Este enlace ha expirado. Por favor, solicita un nuevo cambio de
              contraseña y vuelve a ingresar tu correo electrónico.
            </StyledTypography>
          </Box>
        </Grid>

        <Grid item xs={12} mb={3}>
          <Box textAlign="center">
            <StyledButton
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => authStepAction(AuthStep.SIGN_IN)}
            >
              VOLVER
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
