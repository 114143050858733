import React, { ReactElement, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { RoleAuthorizationAdmin } from '../../rbac/RoleAuthorization';
import { TENANT_ROUTES } from '../../../shared/constants';
import { Loading } from '../../../shared/components/Loading';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchTenant } from '../tenant-actions';

const useStyles = makeStyles(() => ({
  listRoot: {
    height: '100%',
    padding: 0,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(50,50,50,.85)!important',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: 'rgba(50,50,50,.85)!important',
      color: 'white',
    },
    borderRadius: 10,
    marginBottom: 10,
    fontWeight: 'bolder',
  },
  title: {
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 10,
  },
}));

type SettingLayoutProps = {
  children: ReactElement;
};

export const TenantLayout: React.FC<SettingLayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const currentRoute = TENANT_ROUTES.find(
    (route) => route.path.replace(':id', id as string) === location.pathname,
  );

  const [callTenant, loading] = useCallAction(fetchTenant);

  useEffect(() => {
    callTenant(id as string);
  }, [callTenant, id]);

  return (
    <Box height='100%'>
      <Grid
        container
        spacing={2}
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        height='100%'
      >
        <Grid xs={2} item>
          <Paper variant='outlined'>
            <Box p={2}>
              <List
                component='nav'
                classes={{
                  root: classes.listRoot,
                }}
              >
                {TENANT_ROUTES.map((route, key) => {

                  const path = route.path.replace(':id', id as string);


                  return (
                    <RoleAuthorizationAdmin
                      render={() => (
                        <ListItemButton
                          key={key}
                          className={classes.listItem}
                          selected={path === location.pathname}
                          onClick={() => navigate(path)}
                        >
                          <ListItemText primary={route.name} />
                        </ListItemButton>
                      )}
                      error={() => null}
                      permission={route.permission}
                    />
                  );
                })}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Box height='100%'>
            <Paper style={{ height: '100%' }} variant='outlined'>
              <Box p={2}>
                <Box className={classes.title} mb={2}>
                  {currentRoute?.name}
                </Box>
                <Box>
                  {loading ? (
                    <Loading>Cargando Tenant...</Loading>
                  ) : children}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
