import React from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { Comment, CommentStatuses, ReplyComment } from '../comment-types';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  changeCommentStatus,
  changeReplyCommentStatus,
} from '../comment-actions';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

type ChangeCommentStatusDialogProps = {
  open: boolean;
  comment: Comment | ReplyComment;
  status: CommentStatuses | undefined;
  onHandleClose: () => void;
  onSuccess: () => void;
};
export const ChangeCommentStatusDialog: React.FC<
  ChangeCommentStatusDialogProps
> = ({ open, onHandleClose, comment, status, onSuccess }) => {
  const [callAction, loading] = useCallAction(changeCommentStatus, {
    onCompleted: (data) => {
      onHandleClose();
      onSuccess();
      openSnackbarAction(
        `Se ${data.comment?.status === CommentStatuses.APPROVED ? 'aprobó' : 'rechazó'} el comentario con éxito.`,
        TypeMessage.SUCCESS,
      );
    },
    onError: () => {
      openSnackbarAction(
        `Tenemos problemas para ${status === CommentStatuses.APPROVED ? 'aprobar' : 'rechazar'} el comentario`,
        TypeMessage.ERROR,
      );
    },
  });

  const [callReplyAction, loadingReply] = useCallAction(
    changeReplyCommentStatus,
    {
      onCompleted: () => {
        onHandleClose();
        onSuccess();
        openSnackbarAction(
          `Se ${status === CommentStatuses.APPROVED ? 'aprobó' : 'rechazó'} el comentario con éxito.`,
          TypeMessage.SUCCESS,
        );
      },
      onError: () => {
        openSnackbarAction(
          `Tenemos problemas para ${status === CommentStatuses.APPROVED ? 'aprobar' : 'rechazar'} el comentario`,
          TypeMessage.ERROR,
        );
      },
    },
  );

  return (
    <DialogDefault
      title="Cambiar estado"
      isOpen={open}
      handleClose={onHandleClose}
      isLoading={loading || loadingReply}
      handleSuccess={() => {
        if (comment.comment_id) {
          callReplyAction(comment.id, status);
        } else {
          callAction(comment.id, status);
        }
      }}
      showActions
    >
      ¿Estas seguro que deseas{' '}
      {status === CommentStatuses.APPROVED ? 'aprobar' : 'rechazar'} este
      comentario ?
    </DialogDefault>
  );
};
