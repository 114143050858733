import { MagazineList } from './MagazineList';
import { fetchMagazineEvent } from './magazine-events';

export type CreateMagazineInput = {
  title: string;
  published_at: string;
}

export type UpdateMagazineInput = {
  id: string;
  title: string;
  description: string;
  frequency: MagazineFrequency;
  type: MagazineType;
  magazine_category_id: number | null;
  front_page_id: number | null;
  published_at: string;
}

export type WhereMagazine = {
  title?: string;
}

export type MagazineProperties = {
  id: number;
  file_name: string | null;
  page_count: string | null;
  version: string | null;
  file_size: string | null;
  created_at: string;
  updated_at: string;
}

export enum MagazineStatus {
  UnProcessing = 'UNPROCESSING',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED'
}

export enum MagazineType {
  Magazine = 'MAGAZINE',
  Newspaper = 'NEWSPAPPER'
}

export enum MagazineFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY'
}

export type FetchMagazinesEvent = {
  magazines: Magazine[];
  count: number
}

export type MagazineCategory = {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export type FetchMagazineEvent = {
  magazine: Magazine | undefined;
  categories: MagazineCategory[];
}

export type MagazinePage = {
  id: string;
  page: number;
  url: string;
  created_at: string;
  updated_at: string;
}

export type MagazineFrontPage = {
  id: string;
  url: string;
  magazine_page_id: number;
  created_at: string;
  updated_at: string;
}


export type Magazine = {
  id: string;
  title: string;
  description: string;
  url: string;
  category: MagazineCategory | null
  frequency: MagazineFrequency;
  type: MagazineType;
  status: MagazineStatus;
  pages: MagazinePage[];
  magazine_properties: MagazineProperties
  published_at: string;
  magazine_category_id: number | null;
  front_page_id: number | null;
  front_page: MagazineFrontPage;
  created_at: string;
  updated_at: string;
}