import React, { ChangeEvent, useState } from 'react';
import { Grid, Box, Avatar, Typography, TextField } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { authStepAction } from '../auth-actions';
import { AuthStep, SignUpArgs } from '../auth-types';
import { useSignupMutation } from '../../../shared/types/generated';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { universalCookies } from '../../../shared/cookies';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constants';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
export const AuthSignupForm: React.FC = () => {
  const [form, setForm] = useState<SignUpArgs>({
    name: '',
    email: '',
    password: '',
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setForm({
      ...form,
      [event.target.name as keyof SignUpArgs]: event.target.value,
    });
  };

  const [signUp, { loading }] = useSignupMutation({
    onCompleted: (data) => {
      universalCookies.set(USER_COOKIE, data.signup?.token, COOKIES_OPTIONS);
      window.location.href = '/';
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  return (
    <Box
      sx={{
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box display={'flex'} mb={1} alignItems={'center'} width={'100%'}>
        <Avatar sx={{ mr: 1, bgcolor: 'black' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ fontFamily: 'Poppins' }}>
          Registrarse
        </Typography>
      </Box>
      <Box width="100%" component="form" noValidate onSubmit={() => {}} mt={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="lastName"
              variant="standard"
              label="Nombre Completo"
              name="name"
              InputProps={{
                endAdornment: (
                  <AccountCircleIcon
                    style={{ color: 'black' }}
                    color="inherit"
                  />
                ),
              }}
              autoComplete="family-name"
              onChange={handleChange}
              value={form.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              variant="standard"
              label="Correo Electrónico"
              InputProps={{
                endAdornment: (
                  <img
                    alt="logo"
                    width={20}
                    height={20}
                    src="/icon-email.svg"
                  />
                ),
              }}
              name="email"
              autoComplete="email"
              onChange={handleChange}
              value={form.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="standard"
              name="password"
              label="Contraseña"
              InputProps={{
                endAdornment: (
                  <img alt="logo" width={20} height={20} src="/icon-key.svg" />
                ),
              }}
              type="password"
              id="password"
              autoComplete="new-password"
              onChange={handleChange}
              value={form.password}
            />
          </Grid>
        </Grid>
        <ButtonDefault
          fullWidth
          color="primary"
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            background: 'black !important',
            fontFamily: 'Poppins',
          }}
          onClick={() =>
            signUp({
              variables: { ...form },
            })
          }
          isLoading={loading}
        >
          Registrarse
        </ButtonDefault>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                cursor: 'pointer',
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', sm: '13px' },
                textAlign: { xs: 'start', sm: 'end' },
                textDecoration: 'underline',
                color: '#323232d9',
              }}
              onClick={() => authStepAction(AuthStep.SIGN_IN)}
            >
              ¿Ya tienes una cuenta? Iniciar Sesión
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
