import React from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { Box, Typography } from '@mui/material';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  PlanStatuses,
  useUpdatePlanMutation,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { changePlanStatusDialogStore } from '../plan-events';
import { changePlanStatusStoreAction } from '../plan-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

type ChangePlanStatusDialogProps = {
  onSuccess: () => void;
};
export const ChangePlanStatusDialog: React.FC<ChangePlanStatusDialogProps> = ({
  onSuccess,
}) => {
  const { isOpen, plan } = useStore(changePlanStatusDialogStore);

  const [changePlanStatus, { loading }] = useUpdatePlanMutation({
    onCompleted: () => {
      openSnackbarAction(
        `Se ha ${plan?.status === PlanStatuses.Active ? 'desactivado' : 'activado'} plan exitosamente.`,
        TypeMessage.SUCCESS,
      );
      changePlanStatusStoreAction(false, undefined);
      onSuccess();
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar el plan',
        TypeMessage.ERROR,
      );
    },
  });

  return (
    <DialogDefault
      title={'ACTUALIZAR TENANT'}
      isOpen={isOpen}
      handleClose={() => changePlanStatusStoreAction(false, undefined)}
      showActions
      isLoading={loading}
      handleSuccess={() =>
        changePlanStatus({
          variables: {
            input: {
              id: plan?.id as string,
              status:
                plan?.status === PlanStatuses.Active
                  ? PlanStatuses.Inactive
                  : PlanStatuses.Active,
            },
          },
        })
      }
    >
      <Box
        height={100}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5">
          {plan?.status === PlanStatuses.Active
            ? '¿Estas seguro que deseas desactivar el plan?'
            : '¿Estas seguro que deseas activar el plan?'}
        </Typography>
      </Box>
    </DialogDefault>
  );
};
