import React, { useEffect, useState } from 'react';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import { Plan, useUpdatePlanMutation } from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { updatePlanDialogStore } from '../plan-events';
import { updatePlanDialogStoreAction } from '../plan-actions';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { RichText } from '../../../shared/components/inputs/RichText';

type CreatePlanDialogProps = {
  onSuccess: () => void;
};

export const UpdatePlanDialog: React.FC<CreatePlanDialogProps> = ({
  onSuccess,
}) => {
  const { isOpen, plan } = useStore(updatePlanDialogStore);
  const [form, setForm] = useState<Plan | undefined>(plan);

  const [updatePlan, { loading }] = useUpdatePlanMutation({
    onCompleted: () => {
      onSuccess();
      updatePlanDialogStoreAction(false, undefined);
      openSnackbarAction(
        'Se ha creado el plan exitosamente.',
        TypeMessage.SUCCESS,
      );
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para crear el plan',
        TypeMessage.ERROR,
      );
    },
  });

  useEffect(() => {
    if (plan) {
      setForm({
        title: plan.title,
        description: plan.description,
      });
    }
  }, [plan]);

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof Plan]: value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => updatePlanDialogStoreAction(false, undefined)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">CREAR PLAN</DialogTitle>
      <DialogContent style={{ width: 700 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputDefault
              variant="outlined"
              labelName="TITULO"
              value={form?.title}
              name="title"
              onChangeData={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              value={form?.description as string}
              onChange={(value) => {
                setForm({
                  ...form,
                  description: value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDefault
          color="primary"
          variant="outlined"
          onClick={() => updatePlanDialogStoreAction(false, undefined)}
          style={{ width: 136 }}
        >
          Cancelar
        </ButtonDefault>
        <ButtonDefault
          onClick={() => {
            updatePlan({
              variables: {
                input: {
                  id: plan?.id as string,
                  ...form,
                },
              },
            });
          }}
          isLoading={loading}
          style={{ width: 136 }}
        >
          Confirmar
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};
