import React, { useState } from 'react';
import {
  createTenantDialogStore,
  createTenantUserInvitationDialogStore,
  fetchTenantEvent,
} from '../tenant-events';
import { useEvent, useStore } from '@cobuildlab/react-simple-state';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { createTenantUserInvitationDialogStoreAction } from '../tenant-actions';
import { Grid } from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import {
  CreateTenantInput,
  CreateTenantUserInvitationInput,
  TenantUserRoles,
  useCreateTenantUserInvitationMutation,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { TENANT_USER_ROLES_OPTIONS } from '../../../shared/constants';
import { useAuth } from '../../auth/auth-hook';

type CreateTenantUserInvitationDialogProps = {
  onSuccess: () => void;
};

const DEFAULT_VALUES: CreateTenantUserInvitationInput = {
  email: '',
  role: TenantUserRoles.TenantUser,
  tenant: {
    id: null,
  },
};

export const CreateTenantUserInvitationDialog: React.FC<
  CreateTenantUserInvitationDialogProps
> = ({ onSuccess }) => {
  const { isOpen } = useStore(createTenantUserInvitationDialogStore);
  const [form, setForm] =
    useState<CreateTenantUserInvitationInput>(DEFAULT_VALUES);
  const { tenant } = useEvent(fetchTenantEvent);
  const { tenant: currentTenant } = useAuth();
  const tenantId = tenant?.id || currentTenant?.id;

  const [createTenantUserInvitation, { loading }] =
    useCreateTenantUserInvitationMutation({
      onCompleted: () => {
        onSuccess();
        createTenantUserInvitationDialogStoreAction(false);
        openSnackbarAction(
          'Se ha invitado al usuario con éxito',
          TypeMessage.SUCCESS,
        );
        setForm(DEFAULT_VALUES);
      },
      onError: () => {
        openSnackbarAction(
          'Tenemos problemas para invitar al usuario',
          TypeMessage.ERROR,
        );
      },
    });

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof CreateTenantInput]: value,
    });
  };

  return (
    <DialogDefault
      title={'INVITAR USUARIO'}
      isOpen={isOpen}
      handleClose={() => createTenantUserInvitationDialogStoreAction(false)}
      showActions
      isLoading={loading}
      handleSuccess={() => {
        createTenantUserInvitation({
          variables: {
            input: {
              ...form,
              tenant: {
                id: tenantId as string,
              },
            },
          },
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputDefault
            variant="outlined"
            labelName="CORREO ELECTRONICO"
            value={form.email}
            name="email"
            onChangeData={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectDefault
            variant="outlined"
            labelName="Rol"
            value={form.role}
            options={TENANT_USER_ROLES_OPTIONS}
            name="role"
            onChangeData={onChange}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};
