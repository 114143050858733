import { TenantUserRoles, UserRoles } from '../../shared/types/generated';
import { RBACPermissions } from './rbac-types';

export const RBACSchema = [
  {
    Role: UserRoles.Administrator,
    Permissions: [
      RBACPermissions.DASHBOARD_ADMINISTRATOR,
      RBACPermissions.TENANTS,
      RBACPermissions.SETTING_PLANS,
      RBACPermissions.SETTINGS,
      RBACPermissions.CREATE_PLAN,
      RBACPermissions.SETTING_INVITATIONS,
    ],
  },
  {
    Role: UserRoles.User,
    Permissions: [RBACPermissions.SETTINGS],
  },
  {
    Role: TenantUserRoles.TenantUser,
    Permissions: [
      RBACPermissions.DASHBOARD_TENANT,
      RBACPermissions.SETTINGS,
      RBACPermissions.TENANT_ANALYTICS,
      RBACPermissions.TENANT_DASHBOARD_GOOGLE,
    ],
  },
  {
    Role: TenantUserRoles.TenantAdministrator,
    Permissions: [
      RBACPermissions.DASHBOARD_TENANT,
      RBACPermissions.TENANT_ANALYTICS,
      RBACPermissions.SETTINGS,
      RBACPermissions.TENANT_USERS,
      RBACPermissions.SETTING_TENANT,
      RBACPermissions.SETTING_TENANT_INVITATIONS,
      RBACPermissions.SETTING_GLOBAL_USERS,
      RBACPermissions.USERS,
      RBACPermissions.TENANT_PLANS,
      RBACPermissions.TENANT_DASHBOARD_GOOGLE,
      RBACPermissions.TENANT_DASHBOARD_WALL_CRASHES,
      RBACPermissions.TENANT_COMMENTS,
      RBACPermissions.TENANT_DASHBOARD_SUBSCRIPTION,
      RBACPermissions.TENANT_MAGAZINES,
    ],
  },
];
