import React from 'react';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { CardSection } from '../../shared/components/card/CardSection';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Avatar, Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CreateTenantDialog } from './components/CreateTenantDialog';
import {
  changeTenantStatusStoreAction,
  createTenantDialogStoreAction,
} from './tenant-actions';
import {
  Tenant,
  TenantStatuses,
  useTenantsQuery,
} from '../../shared/types/generated';
import { ChangeTenantStatusDialog } from './components/ChangeTenantStatusDialog';
import { getDateByTimezone } from '../../shared/utils';

export const Tenants: React.FC = () => {
  const navigate = useNavigate();

  const { data, loading, refetch } = useTenantsQuery();

  const tenants = (data?.tenants?.items || []) as Tenant[];
  const count = data?.tenants?.count || 0;

  const COLUMNS: TableColumnType<Tenant>[] = [
    {
      columnName: 'LOGO',
      columnValue: null,
      columnComponent: (item: Tenant) => (
        <Box display="flex" justifyContent="center">
          <Avatar
            style={{ borderRadius: '50%' }}
            src={item.logo as string}
            sx={{ width: 30, height: 30 }}
          />
        </Box>
      ),
    },
    {
      columnName: 'NOMBRE',
      columnValue: null,
      columnComponent: (item: Tenant) => (
        <Link
          width={'70px'}
          component="button"
          onClick={() => navigate(`/tenant/${item.id}`)}
        >
          {item.name}
        </Link>
      ),
    },
    {
      columnName: 'PLAN',
      columnValue: (item: Tenant) =>
        item.plan ? (item.plan.title as string) : 'SIN PLAN',
    },
    {
      columnName: 'ESTADO',
      columnValue: (item: Tenant) => item.status as string,
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item: Tenant) =>
        getDateByTimezone(item.createdAt as string, item.timezone),
    },
  ];

  return (
    <>
      <Box
        paddingX={1}
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginBottom={{ xs: 2 }}
      >
        <Typography
          sx={{ textAlign: { xs: 'center', md: 'start' } }}
          fontSize={{ xs: '18px', md: '22px' }}
          marginBottom={{ xs: 1, md: 0 }}
          fontWeight={700}
        >
          {'Tenants'}
        </Typography>
        <ButtonDefault
          onClick={() => createTenantDialogStoreAction(true)}
          startIcon={
            <img src="/icon-add-user.svg" alt="clip" width={14} height={14} />
          }
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
          }}
        >
          Crear Tenant
        </ButtonDefault>
      </Box>
      <CardSection showCardHeader={false}>
        <TableDefault
          items={tenants}
          columns={COLUMNS}
          page={1}
          pageSize={1}
          loading={loading}
          messageLoading={'Cargando tenants...'}
          actions={[
            {
              actionName: (item) =>
                item.status === TenantStatuses.Active
                  ? 'Desactivar'
                  : 'Activar',
              onClick: (item) => changeTenantStatusStoreAction(true, item),
            },
          ]}
        />
      </CardSection>
      <CreateTenantDialog onSuccess={() => refetch()} />
      <ChangeTenantStatusDialog onSuccess={() => refetch()} />
    </>
  );
};
