import React, { useCallback, useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import { uploadFile } from '../../upload';

type RichTextProps = {
  value?: string;
  onChange?: (value: string) => void;
};
export const RichText: React.FC<RichTextProps> = ({ value, onChange }) => {
  const quillObj = useRef<ReactQuill | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);

  const imageHandler = useCallback((): void => {
    const input = ref.current;
    input?.click();
  }, []);

  const container = useMemo(() => {
    return [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
    ];
  }, []);

  return (
    <>
      <input
        type="file"
        hidden
        ref={ref}
        onChange={async (event) => {
          const editor = quillObj.current?.getEditor();
          const file: File | null = event.target.files?.length
            ? event.target.files[0]
            : null;
          const position = editor?.getSelection()?.index || 0;
          if (file) {
            const upload = await uploadFile(file);
            editor?.insertEmbed(position, 'image', upload);
          }
        }}
      />
      <ReactQuill
        ref={quillObj}
        value={value}
        modules={{
          toolbar: {
            container: container,
            handlers: {
              image: imageHandler,
            },
          },
        }}
        theme="snow"
        style={{
          height: 300,
        }}
        onChange={(val: string) => onChange && onChange(val)}
      />
    </>
  );
};
