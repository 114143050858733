import * as yup from 'yup';

export const tenantUserDetailsSchema = yup.object().shape({
  name: yup.string().required('El nombre es requerido'),
  email: yup
    .string()
    .email('El email es inválido')
    .required('El email es requerido'),
  gender: yup.string().required('El género es requerido'),
  birthday: yup.string().nullable(),
  status: yup.string().required('El status es requerido'),
  password: yup.string().notRequired(),
});
