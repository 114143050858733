import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useStylesCustom } from '../../../modules/dashboard/components/DashboardCardItem';
import { useAuth } from '../../../modules/auth/auth-hook';
type CustomHeaderProps = {
  title: string;
  subtitle: string;
};
export const CustomHeader: React.FC<CustomHeaderProps> = ({
  title,
  subtitle,
}) => {
  const {tenant} = useAuth();
  const classes = useStylesCustom();
  return (
    <Container
      style={{ padding: '0px 0px', marginBottom: '16px' }}
      maxWidth={'lg'}
    >
      <Card
        style={{
          padding: '16px 34px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}
      >
        <CardContent className={classes.content}>
          <Stack
            display={'flex'}
            direction={{ xs: 'column-reverse', md: 'row' }}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box marginBottom={{ xs: '10px', md: '0px' }}>
              <Typography
                marginBottom={{ xs: '5px', md: '10px' }}
                textAlign={{ xs: 'center', md: 'start' }}
                lineHeight={{ sx: '24px', md: '24px' }}
                fontSize={{ sx: '16px', md: '24px' }}
                fontWeight={700}
              >
                {title}
              </Typography>
              <Typography
                textAlign={{ xs: 'center', md: 'start' }}
                color={'#7B7B7B'}
                lineHeight={{ sx: '24px', md: '16px' }}
                fontSize={{ sx: '14px', md: '16px' }}
              >
                {subtitle}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              sx={{ height: '45px', position: 'relative' }}
            >
              <img
                src={
                  tenant?.id !== '65b39e5af17e852e77abc149'
                    ? '/logo-easy.png'
                    : '/vangdata.png'
                }
                alt="easy logo"
                height={'100%'}

              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};
