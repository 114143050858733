import React, { useState } from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { createTenantDialogStoreAction } from '../tenant-actions';
import { Grid } from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import {
  CreateTenantInput,
  useCreateTenantMutation,
} from '../../../shared/types/generated';
import { useStore } from '@cobuildlab/react-simple-state';
import { createTenantDialogStore } from '../tenant-events';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

type CreateTenantDialogProps = {
  onSuccess: () => void
}


const DEFAULT_VALUES: CreateTenantInput = {
  name: '',
  webhook: '',
};

export const CreateTenantDialog: React.FC<CreateTenantDialogProps> =
  ({
     onSuccess,
   }) => {
    const [form, setForm] = useState<CreateTenantInput>(DEFAULT_VALUES);
    const { isOpen } = useStore(createTenantDialogStore);
    const [createTenant, { loading }] = useCreateTenantMutation({
      onCompleted: () => {
        createTenantDialogStoreAction(false);
        onSuccess();
        openSnackbarAction(
          'Se ha creado el tenant con éxito',
          TypeMessage.SUCCESS,
        );
      },
      onError: () => {
        openSnackbarAction(
          'Tenemos problemas para crear el tenant',
          TypeMessage.ERROR,
        );
      },
    });

    const onChange = (field: string, value: string): void => {
      setForm({
        ...form,
        [field as keyof CreateTenantInput]: value,
      });
    };


    return (<DialogDefault
      title={'INVITAR USUARIO'}
      isOpen={isOpen}
      handleClose={() => createTenantDialogStoreAction(false)}
      showActions
      isLoading={loading}
      handleSuccess={() => {
        createTenant({
          variables: {
            input: {
              ...form,
            },
          },
        });
      }}
    >
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <InputDefault
            variant='outlined'
            labelName='NOMBRE'
            value={form.name}
            name='name'
            onChangeData={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <InputDefault
            variant='outlined'
            labelName='WEBHHOK'
            value={form.webhook}
            name='webhook'
            onChangeData={onChange}
          />
        </Grid>
      </Grid>
    </DialogDefault>);
  };