import React from 'react';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { Box, Typography } from '@mui/material';

interface DataTableProps {
  id: string;
  title: string;
  count: string;
  total: string;
  isTotal: boolean;
}

type DashboardSubscriptionByPriceTableProps = {
  loading?: boolean;
  data: Array<DataTableProps>;
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};
export const DashboardSubscriptionByPriceTable: React.FC<
  DashboardSubscriptionByPriceTableProps
> = ({ loading = false, data, page, pageSize, total, onPageChange }) => {
  const COLUMNS: TableColumnType<DataTableProps>[] = [
    {
      columnName: <Typography color={'#BBBBBB'}>PLAN</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Box
          textAlign="left"
          sx={{
            wordWrap: 'break-word',
            ...(item.isTotal && {
              fontSize: '16px',
              fontWeight: 800,
            }),
          }}
        >
          {item.title}
        </Box>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>CANTIDAD</Typography>,
      columnValue: null,
      columnComponent: (item) => (
        <Box
          textAlign="center"
          sx={{
            ...(item.isTotal && {
              fontSize: '16px',
              fontWeight: 800,
            }),
          }}
        >
          {item.count}
        </Box>
      ),
    },
    {
      columnName: (
        <Typography color={'#BBBBBB'} textAlign="right">
          TOTAL
        </Typography>
      ),
      columnValue: null,
      columnComponent: (item) => (
        <Box
          textAlign="right"
          sx={{
            ...(item.isTotal && {
              fontSize: '16px',
              fontWeight: 800,
            }),
          }}
        >
          {item.total}
        </Box>
      ),
    },
  ];

  return (
    <TableDefaultSticky
      count={total}
      items={data}
      columns={COLUMNS}
      page={page}
      handleChangePage={onPageChange}
      pageSize={pageSize}
      loading={loading}
      messageLoading={'Cargando...'}
    />
  );
};
