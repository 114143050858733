import React, { useEffect } from 'react';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { Box, Container, Grid, Tab, tabClasses, Tabs } from '@mui/material';
import { MAGAZINE_DETAIL_OPTIONS } from './magazine-constants';
import { Loading } from '../../shared/components/Loading';
import { TabItem } from '../../shared/components/tab/TabItem';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchMagazineAction } from './magazine-actions';
import { useParams } from 'react-router-dom';
import { useAuth } from '../auth/auth-hook';
import { MagazineDetailTab } from './components/MagazineDetailTab';
import { MagazinePageTab } from './components/MagazinePageTab';
import { makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fetchAuthStore } from '../auth/auth-events';

export interface StyleProps {
  color: string;
  backgroundColor: string;
}


export const Magazine: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { user, tenant } = useAuth();


  const [callMagazine, loading] = useCallAction(fetchMagazineAction);
  const { id } = useParams<{ id: string }>();



  useEffect(() => {
    console.log({ id });
    callMagazine(id as string);
  }, [callMagazine, id]);

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    console.log({ newValue });
    setCurrentTab(newValue ?? 0);
  };


  const MuiTabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor:
        (tenant?.identityColor ?? '#000000') + '20' + '!important',
    },
  });



  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}
      >
        <Box height='100%'>
          <Grid
            container
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='stretch'
            height='100%'
          >
            <Grid xs={12} item>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  paddingBottom: 1,
                }}
              >
                <Tabs
                  sx={{
                    padding: { sm: '0px 10px !important' },

                    ['& .MuiTabs-indicator']: {
                      display: 'none !important',
                    },
                  }}
                  value={currentTab}
                  onChange={handleChange}
                  aria-label='scrollable auto tabs example'
                  variant='scrollable'
                  allowScrollButtonsMobile
                >
                  {MAGAZINE_DETAIL_OPTIONS.map((route, key) => (
                    <MuiTabItem
                      key={key}
                      label={route.name}
                    />
                  ))}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <Loading height='200px' />
              ) : (
                <Box>
                  {!currentTab && <MagazineDetailTab />}
                  {currentTab === 1 && <MagazinePageTab />}
                </Box>
              )}

            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};