import { createEvent } from '@cobuildlab/react-simple-state';
import { Comment, PostComment } from './comment-types';

export const fetchCommentsEvent = createEvent<{
  comments: PostComment[];
  count: number;
}>({
  initialValue: {
    comments: [],
    count: 0,
  },
});

export const fetchCommentsErrorEvent = createEvent();

export const fetchCommentEvent = createEvent<{
  comments: Comment[];
  count: number;
}>({
  initialValue: {
    comments: [],
    count: 0,
  },
});

export const fetchCommentErrorEvent = createEvent();

export const changeCommentStatusEvent = createEvent<{ comment?: Comment }>({
  initialValue: {
    comment: undefined,
  },
});

export const changeCommentStatusErrorEvent = createEvent();

export const changeReplyCommentStatusEvent = createEvent<{ comment?: Comment }>(
  {
    initialValue: {
      comment: undefined,
    },
  },
);

export const changeReplyCommentStatusErrorEvent = createEvent();
