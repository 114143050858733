import React, { useState } from 'react';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  CreateTenantInput,
  CreateUserInvitationInput,
  TenantUserRoles,
  useCreateUserInvitationMutation,
  UserRoles,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { Grid } from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { USER_ROLES_OPTIONS } from '../../../shared/constants';
import { createUserInvitationDialogStore } from '../user-events';
import { createUserInvitationDialogStoreAction } from '../user-actions';

type CreateUserInvitationDialogProps = {
  onSuccess: () => void;
};

const DEFAULT_VALUES: CreateUserInvitationInput = {
  email: '',
  role: UserRoles.Administrator,
};

export const CreateUserInvitationDialog: React.FC<
  CreateUserInvitationDialogProps
> = ({ onSuccess }) => {
  const { isOpen } = useStore(createUserInvitationDialogStore);
  const [form, setForm] = useState<CreateUserInvitationInput>(DEFAULT_VALUES);
  const [createUserInvitation, { loading }] = useCreateUserInvitationMutation({
    onCompleted: () => {
      onSuccess();
      createUserInvitationDialogStoreAction(false);
      openSnackbarAction(
        'Se ha invitado al usuario con éxito',
        TypeMessage.SUCCESS,
      );
      setForm(DEFAULT_VALUES);
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para invitar al usuario',
        TypeMessage.ERROR,
      );
    },
  });

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof CreateTenantInput]: value,
    });
  };

  return (
    <DialogDefault
      title={'INVITAR USUARIO'}
      isOpen={isOpen}
      handleClose={() => createUserInvitationDialogStoreAction(false)}
      showActions
      isLoading={loading}
      handleSuccess={() => {
        createUserInvitation({
          variables: {
            input: {
              ...form,
            },
          },
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputDefault
            variant="outlined"
            labelName="CORREO ELECTRONICO"
            value={form.email}
            name="email"
            onChangeData={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectDefault
            variant="outlined"
            labelName="Rol"
            value={form.role}
            options={USER_ROLES_OPTIONS}
            name="role"
            onChangeData={onChange}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};
