import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { DateFilter, DEFAULT_TIMEZONE } from '../../shared/constants';
import { CountData, GenerateUserReport, WinnerNote } from './dashboard-types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { GlobalUser } from '../user/user-types';

export const currentTime = momentTimezone()
  .tz(DEFAULT_TIMEZONE)
  .startOf('day')
  .format();

export const fetchUsersCountStore = createStore<{
  loading: boolean;
  users: CountData[];
}>({
  initialValue: {
    loading: true,
    users: [],
  },
});

export const changeDateFilterStore = createStore<{ date: DateFilter }>({
  initialValue: {
    date: DateFilter.TODAY,
  },
});

export const changeDashboardDateFilterStore = createStore<{
  from: string;
  to: string;
  filter: DateFilter;
}>({
  initialValue: {
    from: moment().utc(true).startOf('day').format(),
    to: moment().utc(true).endOf('day').format(),
    filter: DateFilter.TODAY,
  },
});

export const changeWinnerNotesOrderStore = createStore<{
  order: 'asc' | 'desc';
}>({
  initialValue: {
    order: 'desc',
  },
});

export const winnerNoteEvents = createEvent<{
  data: WinnerNote[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const winnerNoteErrorEvents = createEvent<Error>();

export const winnerNotePaginateEvents = createEvent<{
  data: WinnerNote[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const winnerNoteErrorPaginateEvents = createEvent<Error>();

export const changeGoogleDateFilterStore = createStore<{
  from: string;
  to: string;
  filter: DateFilter;
}>({
  initialValue: {
    from: moment(currentTime).toISOString(),
    to: moment().toISOString(),
    filter: DateFilter.TODAY,
  },
});
export const selectedSectionStore = createStore<{ section: string }>({
  initialValue: {
    section: '',
  },
});

export const fetchGenerateUserReportEvent = createEvent<{
  report: GenerateUserReport[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchGenerateUserReportErroEvent = createEvent<Error>();

export const fetchGenerateWinnerReportEvent = createEvent<{
  report: WinnerNote[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchGenerateWinnerReportErroEvent = createEvent<Error>();
