import React from 'react';
import { Container, Grid } from '@mui/material';
import { PlanStatuses, usePlansQuery } from '../../shared/types/generated';
import { CreatePlanDialog } from '../plan/components/CreatePlanDialog';
import { TenantPlanCard } from './components/TenantPlanCard';

export const TenantPlans: React.FC = () => {
  const { data, refetch } = usePlansQuery({
    variables: {
      where: {
        status: PlanStatuses.Active,
      },
    },
  });
  const plans = data?.plans?.items || [];

  return (
    <>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {plans.map((plan) => (
            <Grid item key={plan?.title} xs={12} md={4}>
              <TenantPlanCard plan={plan} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <CreatePlanDialog onSuccess={() => refetch({})} />
    </>
  );
};
