import React, { useEffect } from 'react';
import { CardSection } from '../../../shared/components/card/CardSection';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import {
  SwgTapByUrlOrder,
  useSwgTapByUrlReportLazyQuery,
  useSwgTapByUrlReportMetricLazyQuery,
} from '../../../shared/types/generated';
import * as XLSX from 'xlsx';
import { getDateFilterText } from '../dashboard-utils';
import { saveAs } from 'file-saver';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  changeDashboardDateFilterStore,
  selectedSectionStore,
} from '../dashboard-events';
import { useAuth } from '../../auth/auth-hook';
import { DashboardGoogleWinnerNote } from './DashboardGoogleWinnerNote';
import { DashboardGoogleWinnerNotesPie } from './DashboardGoogleWinnerNotesPie';
import { SwgTapByUrlDataResponseWithIdObj } from '../../analytic/analytic-types';
import { useStylesCustom } from './DashboardCardItem';

export const DashboardGoogleWinnerNoteContainer: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const { section } = useStore(selectedSectionStore);

  const [callAction, { data, loading }] = useSwgTapByUrlReportLazyQuery();

  const [callReport, { loading: loadingReport }] =
    useSwgTapByUrlReportMetricLazyQuery({
      onCompleted: (res) => {
        console.log({ res });
        const worksheet = XLSX.utils.json_to_sheet(
          (res.swgTapByUrlReportMetric?.data || []).map((r) => ({
            url: r?.url,
            cantidad: r?.count,
          })),
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `${getDateFilterText(from, to, filter)}`,
        );
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });
        saveAs(
          blob,
          `Reporte google one tap ${getDateFilterText(from, to, filter)}.xlsx`,
        );
      },
    });

  useEffect(() => {
    callAction({
      variables: {
        where: {
          from,
          to,
          tenantId: tenant?.id,
          section,
          order: SwgTapByUrlOrder.Desc,
        },
        page: 0,
        pageSize: 10,
      },
    });
  }, [section, from, to, tenant, callAction]);

  const items = (data?.swgTapByUrlReport?.data ||
    []) as SwgTapByUrlDataResponseWithIdObj[];
  const total = data?.swgTapByUrlReport?.total || 0;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={1} marginBottom={2}>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              component={'div'}
              sx={{ marginBottom: { xs: '10px', md: '0px' } }}
              fontSize={20}
              fontWeight={700}
            >
              Notas Ganadoras
            </Typography>
            <ButtonDefault
              onClick={() =>
                callReport({
                  variables: {
                    where: {
                      tenantId: tenant?.id,
                      from,
                      to,
                      section,
                    },
                  },
                })
              }
              startIcon={
                loadingReport ? (
                  <img src="/icon-clip.svg" alt="clip" width={14} height={14} />
                ) : null
              }
              isLoading={loadingReport}
              style={{
                width: 190,
                height: 40,
                textTransform: 'inherit',
                fontWeight: 700,
                background: tenant?.identityColor ?? 'black',
              }}
            >
              Descargar Reporte
            </ButtonDefault>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 2,
            md: 1,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardGoogleWinnerNote
                loading={loading}
                data={items}
                total={total}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 2,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardGoogleWinnerNotesPie data={items} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
