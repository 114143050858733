import { useState } from 'react';
import { SortOrder, SortTableInput } from '../../types/generated';

interface UseTableSortReturn {
  sort?: SortTableInput | undefined;
  handleSort: (field: string, order: 'asc' | 'desc') => void;
}

export const useTableSort = (): UseTableSortReturn => {
  const [sort, setSort] = useState<SortTableInput>();

  const handleSort = (field: string, order: 'asc' | 'desc'): void => {
    setSort({
      field,
      order: order === 'asc' ? SortOrder.Asc : SortOrder.Desc,
    });
  };

  return { sort, handleSort };
};
