import React, { useEffect } from 'react';
import { Loading } from '../../shared/components/Loading';
import { useNavigate } from 'react-router-dom';
import { universalCookies } from '../../shared/cookies';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../shared/constants';
import { CustomSizes } from '../../shared/components/loading-types';

export const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    universalCookies.remove(USER_COOKIE, COOKIES_OPTIONS);
    window.location.href = '/';
  }, [navigate]);

  return <Loading customSize={CustomSizes.SMALL} />;
};
