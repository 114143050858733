import React from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { useNavigate } from 'react-router-dom';
import {
  Plan,
  PlanStatuses,
  TenantStatuses,
  usePlansQuery,
} from '../../shared/types/generated';
import { TableColumnType } from '../../shared/components/table/table-types';
import { CreatePlanDialog } from './components/CreatePlanDialog';
import {
  changePlanStatusStoreAction,
  createPlanDialogStoreAction,
  updatePlanDialogStoreAction,
} from './plan-actions';
import { getDateByTimezone, sanitizePlanStatus } from '../../shared/utils';
import { ChangePlanStatusDialog } from './components/ChangePlanStatusDialog';
import { changeTenantStatusStoreAction } from '../tenant/tenant-actions';
import { UpdatePlanDialog } from './components/UpdatePlanDialog';
import { Box, Typography } from '@mui/material';

export const Plans: React.FC = () => {
  const { data, loading, refetch } = usePlansQuery();

  const tenants = (data?.plans?.items || []) as Plan[];
  const count = data?.plans?.count || 0;

  const COLUMNS: TableColumnType<Plan>[] = [
    {
      columnName: 'TITULO',
      columnValue: (item: Plan) => item.title as string,
    },
    {
      columnName: 'DESCRIPCIÓN',
      columnValue: null,
      columnComponent: (item: Plan) => (
        <div
          dangerouslySetInnerHTML={{ __html: item?.description as string }}
        ></div>
      ),
    },
    {
      columnName: 'STATUS',
      columnValue: (item: Plan) =>
        sanitizePlanStatus(item.status as PlanStatuses),
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item: Plan) =>
        getDateByTimezone(item.createdAt as string, undefined),
    },
  ];
  /**actions={
          <>
            <ButtonDefault onClick={() => createPlanDialogStoreAction(true)}>
              CREAR PLAN
            </ButtonDefault>
          </>
        } */
  return (
    <>
      <Box
        paddingX={1}
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginBottom={{ xs: 2 }}
      >
        <Typography
          sx={{ textAlign: { xs: 'center', md: 'start' } }}
          fontSize={{ xs: '18px', md: '22px' }}
          marginBottom={{ xs: 1, md: 0 }}
          fontWeight={700}
        >
          {'Planes'}
        </Typography>
        <ButtonDefault
          onClick={() => createPlanDialogStoreAction(true)}
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
          }}
        >
          Crear plan
        </ButtonDefault>
      </Box>
      <CardSection showCardHeader={false}>
        <TableDefault
          items={tenants}
          columns={COLUMNS}
          page={1}
          pageSize={1}
          loading={loading}
          messageLoading={'Cargando planes...'}
          actions={[
            {
              actionName: (item) =>
                item.status === PlanStatuses.Active ? 'Desactivar' : 'Activar',
              onClick: (item) => changePlanStatusStoreAction(true, item),
            },
            {
              actionName: () => 'Actualizar',
              onClick: (item) => updatePlanDialogStoreAction(true, item),
            },
          ]}
        />
      </CardSection>
      <CreatePlanDialog onSuccess={() => refetch()} />
      <ChangePlanStatusDialog onSuccess={() => refetch()} />
      <UpdatePlanDialog onSuccess={() => refetch()} />
    </>
  );
};
