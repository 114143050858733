import React, { ReactNode } from 'react';
import { Card, CardHeader, CardContent, Theme, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading } from '../../../shared/components/Loading';
import { CustomSizes } from '../../../shared/components/loading-types';

export const useStylesCustom = makeStyles((theme: Theme) => ({
  header: {
    //backgroundColor: PRIMARY_COLOR,
    padding: '0px!important',
    color: 'black',
    textAlign: 'start',
  },
  title: {
    fontSize: '16px!important',
    textAlign: 'start',
    lineHeight: '18px',
    padding: '0px',
    fontWeight: 500,
  },
  content: {
    padding: '0px 0px!important',
    fontWeight: 600,
  },
}));

type DashboardCardItemProps = {
  title?: string | ReactNode;
  content?: string | number;
  subContent?: string | number;
  loading?: boolean;
};

export const DashboardCardItem: React.FC<DashboardCardItemProps> = ({
  title = '',
  content = '',
  loading = false,
  subContent = '',
}) => {
  const classes = useStylesCustom();

  return (
    <Card
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.header}
        title={title}
      />

      <CardContent className={classes.content}>
        <Box fontSize={24} display="flex">
          {loading ? (
            <Loading height="100%" customSize={CustomSizes.SMALL} />
          ) : (
            <Box>
              <Box>{content.toLocaleString()}</Box>
              {subContent && <Box fontWeight="bold">{subContent}</Box>}
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
