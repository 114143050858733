import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Loading } from '../../shared/components/Loading';
import { CardSection } from '../../shared/components/card/CardSection';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchComment } from './comment-actions';
import { useParams } from 'react-router-dom';
import { fetchCommentEvent } from './comment-events';
import { CommentContainer } from './components/CommentContainer';
import { ShowMoreComments } from './components/ShowMoreComments';

export const Comment: React.FC = () => {
  const { comments, count } = useEvent(fetchCommentEvent);
  const [callComment, loading] = useCallAction(fetchComment);
  const { id } = useParams<{ id: string }>();

  const [{ page, pageSize }, setPagination] = useState({
    pageSize: 5,
    page: 1,
  });
  useEffect(() => {
    if (id) {
      callComment(id, page - 1, pageSize);
    }
  }, [callComment, id, page, pageSize]);

  return (
    <CardSection
      title={
        <Typography variant="h5" fontWeight={800}>
          Comentarios
        </Typography>
      }
    >
      <Grid container p={2}>
        {loading ? (
          <Grid item xs={12}>
            <Loading height="300px">Cargando...</Loading>
          </Grid>
        ) : (
          comments.map((comment) => (
            <Grid item xs={12}>
              <CommentContainer
                comment={comment}
                onRefetch={() => {
                  if (id) callComment(id, page - 1, pageSize);
                }}
              />
            </Grid>
          ))
        )}
        <ShowMoreComments
          loadMoreComments={() => {
            setPagination({
              page,
              pageSize: pageSize + 5,
            });
          }}
          loading={loading}
          count={count}
          comments={comments}
        />
      </Grid>
    </CardSection>
  );
};
