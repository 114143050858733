import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  createUserInvitationDialogStore,
  fetchGlobalRegisterUsersErrorEvent,
  fetchGlobalRegisterUsersEvent,
  fetchGlobalUsersErrorEvent,
  fetchGlobalUsersEvent,
  fetchUserByMonthsErrorEvents,
  fetchUserByMonthsEvents,
  fetchUserByProviderErrorEvents,
  fetchUserByProviderEvents,
  updateUserErrorEvent,
  updateUserEvent,
  fetchUserCountErrorEvent,
  fetchUserCountEvent,
} from './user-events';
import {
  SortTableInput,
  Tenant,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from '../../shared/types/generated';
import axios from 'axios';
import { GlobalUser, GlobalUserJoinSocialProvider } from './user-types';

import { clientUpload } from '../../shared/apollo';
import { DateFilter } from '../../shared/constants';

export const fetchUserByProviders = async (data: {
  tenant: Tenant;
  from: string;
  to: string;
}): Promise<{ count: number; provider: string }[]> => {
  const { tenant, to, from } = data;
  const response = await axios.get<{ count: number; provider: string }[]>(
    `${tenant.webhook}/api/user/provider-count`,
    {
      params: {
        to,
        from,
      },
    },
  );

  return response.data;
};

export const fetchUserByMonth = createAction(
  fetchUserByMonthsEvents,
  fetchUserByMonthsErrorEvents,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    filter: DateFilter;
  }) => {
    const { tenant, from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${tenant.webhook}/api/user/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
        },
      },
    );

    return response.data;
  },
);

export const fetchUserByProviderMetrics = createAction(
  fetchUserByProviderEvents,
  fetchUserByProviderErrorEvents,
  fetchUserByProviders,
);

export const fetchGlobalUsers = createAction(
  fetchGlobalUsersEvent,
  fetchGlobalUsersErrorEvent,
  async (data: {
    tenant: Tenant | undefined | null;
    page: number;
    pageSize: number;
    search: string;
    provider: string;
    from: string;
    to: string;
    orderBy?: SortTableInput;
  }) => {
    const {
      tenant,
      page,
      search = '',
      provider,
      from,
      to,
      pageSize,
      orderBy,
    } = data;

    let params: Record<string, unknown> = {
      page,
      provider,
      from,
      to,
      pageSize,
    };

    if (search) {
      params = { ...params, search };
    }

    if (orderBy) {
      params = {
        ...params,
        orderByField: orderBy.field,
        orderByOrder: orderBy.order,
      };
    }

    const response = await axios.get<{
      data: GlobalUserJoinSocialProvider[];
      total: number;
    }>(`${tenant?.webhook}/api/user/all`, { timeout: 60000, params });

    return response.data;
  },
);

export const fetchGlobalRegisterUsers = createAction(
  fetchGlobalRegisterUsersEvent,
  fetchGlobalRegisterUsersErrorEvent,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    page: number;
    pageSize: number;
    orderBy?: SortTableInput;
  }) => {
    const { tenant, to, from, page, orderBy, pageSize } = data;

    const response = await axios.get<{
      data: GlobalUserJoinSocialProvider[];
      total: number;
    }>(`${tenant.webhook}/api/user/all-by-filters`, {
      timeout: 60000,
      params: {
        to,
        from,
        page,
        pageSize,
        orderByField: orderBy?.field,
        orderByOrder: orderBy?.order,
      },
    });

    return response.data;
  },
);

export const updateUserAction = createAction(
  updateUserEvent,
  updateUserErrorEvent,
  async (variables: UpdateUserMutationVariables) => {
    const response = await clientUpload.mutate<
      UpdateUserMutation,
      UpdateUserMutationVariables
    >({
      mutation: UpdateUserDocument,
      variables,
    });

    return {
      user: response.data?.updateUser as User,
    };
  },
);

export const createUserInvitationDialogStoreAction = createStoreAction(
  createUserInvitationDialogStore,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);
export const fetchUserCount = createAction(
  fetchUserCountEvent,
  fetchUserCountErrorEvent,
  async (data: { tenant: Tenant | undefined | null }) => {
    const { tenant } = data;
    const response = await axios.get<{ count: number }>(
      `${tenant?.webhook}/api/user/count`,
    );
    return {
      count: response.data.count,
    };
  },
);
