import React from 'react';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { DashboardCardItem } from '../DashboardCardItem';
import { Typography } from '@mui/material';
import { getSubscriptionDateFilterTitleText } from '../../dashboard-utils';
import { fetchSubscriptionCountStore } from '../../../subscription/subscription-events';

export const DashboardTotalSubscriptionByFilterItem: React.FC = () => {
  const { loading, subscriptions } = useStore(fetchSubscriptionCountStore);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);

  const content = subscriptions.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  return (
    <DashboardCardItem
      title={getSubscriptionDateFilterTitleText(from, to, filter)}
      content={content}
      loading={loading}
    />
  );
};
