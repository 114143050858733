import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { Tenant, TenantUser } from '../../shared/types/generated';
import {
  GlobalUser,
  GlobalUserJoinSocialProvider,
  NewGlobalUser,
} from '../user/user-types';

export const createTenantDialogStore = createStore<{ isOpen: boolean }>({
  initialValue: {
    isOpen: false,
  },
});

export const createTenantUserInvitationDialogStore = createStore<{
  isOpen: boolean;
}>({
  initialValue: {
    isOpen: false,
  },
});

export const fetchTenantEvent = createEvent<{
  tenant: Tenant | undefined | null;
}>({
  initialValue: {
    tenant: undefined,
  },
});

export const fetchTenantErrorEvent = createEvent();

export const updateTenantEvent = createEvent<{ tenant: Tenant | undefined }>({
  initialValue: {
    tenant: undefined,
  },
});

export const updateTenantErrorEvent = createEvent<Error>();

export const changeTenantStatusDialogStore = createStore<{
  isOpen: boolean;
  tenant: Tenant | undefined;
}>({
  initialValue: {
    isOpen: false,
    tenant: undefined,
  },
});

export const changeTenantUserStatusDialogStore = createStore<{
  isOpen: boolean;
  tenantUser: TenantUser | undefined;
}>({
  initialValue: {
    isOpen: false,
    tenantUser: undefined,
  },
});

export const updateTenantUserDialogStore = createStore<{
  isOpen: boolean;
  tenantUser: TenantUser | undefined;
}>({
  initialValue: {
    isOpen: false,
    tenantUser: undefined,
  },
});

export const updateGlobalUserEvent = createEvent<void>();
export const updateGlobalUserEventError = createEvent<Error>();

export const fetchGlobalUserEvent = createEvent<GlobalUserJoinSocialProvider>();
export const fetchGlobalUserEventError = createEvent<Error>();
