import React from 'react';
import { Box, Typography, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

interface InputDefaultProps<TDate = unknown> extends DatePickerProps<TDate> {
  labelName?: string;
  textFieldProps?: TextFieldProps;
}

export const DatePickerDefault: React.FC<InputDefaultProps> = ({
  labelName = '',
  textFieldProps,
  ...rest
}) => {
  return (
    <Box width={'100%'}>
      {labelName && <Typography>{labelName}</Typography>}
      <DatePicker
        sx={{ width: '100%', borderRadius: '8px' }}
        slotProps={{
          textField: {
            variant: 'outlined',
            fullWidth: true,
            size: 'small',
            InputLabelProps: {
              shrink: true,
              style: { fontSize: 19 },
            },
            inputProps: {
              style: {
                paddingLeft: 8,
              },
            },
            InputProps: {
              style: {
                height: 48,
                borderRadius: '8px',
                background: '#F6F6F6',
              },
            },
            ...textFieldProps
          },
        }}
        {...rest}
      />
    </Box>
  );
};
