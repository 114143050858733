import React from 'react';
import { Comment, ReplyComment, CommentStatuses } from '../comment-types';
import { Chip, Typography } from '@mui/material';

type CommentStatusProps = {
  comment: Comment | ReplyComment;
};
export const CommentStatus: React.FC<CommentStatusProps> = ({ comment }) => {
  let color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  let label;

  switch (comment.status) {
    case CommentStatuses.APPROVED:
      color = 'success';
      label = 'APROBADO';
      break;
    case CommentStatuses.REJECTED:
      color = 'error';
      label = 'RECHAZADO';
      break;
    default:
      color = 'warning';
      label = 'PENDIENTE';
  }

  return (
    <Chip
      color={color}
      label={
        <Typography fontSize={16} fontWeight={700}>
          {label}
        </Typography>
      }
    />
  );
};
