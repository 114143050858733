import React from 'react';
import { Grid } from '@mui/material';
import { CommentItem } from './CommentItem';
import { ReplyComment } from '../comment-types';

type ReplyCommentsProps = {
  replyComments: ReplyComment[];
  onRefetch: () => void;
};

export const ReplyComments: React.FC<ReplyCommentsProps> = ({
  replyComments,
  onRefetch,
}) => {
  return (
    <Grid container spacing={2}>
      {replyComments?.map((replyComment) => (
        <Grid item xs={12}>
          <CommentItem comment={replyComment} onRefetch={onRefetch} />
        </Grid>
      ))}
    </Grid>
  );
};
