import React from 'react';
import { Box, Link, Typography } from '@mui/material';

export const Copyright: React.FC = () => {
  return (
    <Box
      display={'flex'}
      borderTop={'1px solid #B9B9B9'}
      justifyContent={'space-between'}
      alignItems={'center'}
      px={{ xs: 2, md: 4 }}
      py={{ xs: 1, md: 2 }}
    >
      <img src="/logo-easy.png" alt="logo" width={123} height={50} />
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://digitalroom.tech/">
          DigitalRoom.tech
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
};
