import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { ReplyComments } from './ReplyComments';
import { CommentItem } from './CommentItem';
import { Comment, ReplyComment } from '../comment-types';

type CommentContainerProps = {
  comment: Comment;
  onRefetch: () => void;
};
export const CommentContainer: React.FC<CommentContainerProps> = ({
  comment,
  onRefetch,
}) => {
  const haveReplyComments = comment.replies?.length;

  return (
    <Grid container>
      <Grid xs={12} item mb={2}>
        <CommentItem comment={comment} onRefetch={onRefetch} />
      </Grid>
      {haveReplyComments ? (
        <Grid xs={12} item mb={2}>
          <Grid container>
            <Grid item xs={1}>
              <Box display="flex" height="100%" justifyContent="center">
                <Divider orientation="vertical" flexItem />
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2}>
                {haveReplyComments ? (
                  <Grid item xs={12}>
                    <ReplyComments
                      replyComments={(comment.replies || []) as ReplyComment[]}
                      onRefetch={onRefetch}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
