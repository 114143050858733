import React, { ChangeEvent, useRef, useState } from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { Box, Grid, Typography } from '@mui/material';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { createMagazineDialogStore } from '../magazine-stores';
import { createMagazineAction, createMagazineDialogStoreAction } from '../magazine-actions';
import { useAuth } from '../../auth/auth-hook';
import hexToRgba from 'hex-to-rgba';
import UploadIcon from '@mui/icons-material/Upload';
import { CreateMagazineInput } from '../magazine-types';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import { RichText } from '../../../shared/components/inputs/RichText';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

const DEFAULT_VALUES = {
  title: '',
  published_at: '',
};
export const CreateMagazineDialog: React.FC = () => {
  const { isOpen } = useStore(createMagazineDialogStore);
  const { tenant } = useAuth();
  const ref = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [form, setForm] = useState<CreateMagazineInput>(DEFAULT_VALUES);

  const [callback, loading] = useCallAction(createMagazineAction, {
    onCompleted: () => {
      setFile(null);
      setForm(DEFAULT_VALUES);
      openSnackbarAction(
        'Se ha actualizado correctamente.',
        TypeMessage.SUCCESS,
      );
    },
    onError:()=>{
      openSnackbarAction(
        'Tenemos problemas para actualizar',
        TypeMessage.ERROR,
      );
    }
  });
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      setFile(event.target.files[0]);
    }
  };

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof CreateMagazineInput]: value,
    });
  };


  return (
    <DialogDefault
      title={'CREAT REVISTA DIGITAL'}
      isOpen={isOpen}
      handleClose={() => {
        createMagazineDialogStoreAction(false);
        setFile(null);
      }}
      showActions
      handleSuccess={() => {
        if (file) {
          callback(file, form);
        }
      }}
      isLoading={loading}
    >


      <Grid container spacing={1}>
        <Grid item xs={12}>
          <InputDefault
            variant='outlined'
            labelName='TITULO'
            value={form.title}
            name='title'
            onChangeData={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <InputDefault
              variant='outlined'
              labelName='FECHA DE PUBLICACIÓN'
              value={form.published_at}
              name='published_at'
              type='date'
              onChangeData={onChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>PDF</Typography>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height={100}
            onClick={() => {
              if (ref.current) {
                ref.current?.click();
              }
            }}
            width='100%'
            style={{
              cursor: 'pointer',
              border: `1px solid ${hexToRgba(tenant?.identityColor as string, 0.5)}`,
              borderRadius: 5,
            }}
          >
            {
              file ? (
                <Typography color={hexToRgba(tenant?.identityColor as string, 0.7)} fontWeight='bold'>
                  {file.name}
                </Typography>
              ) : (
                <Box textAlign='center'>
                  <UploadIcon sx={{ color: hexToRgba(tenant?.identityColor as string, 0.7), fontSize: 30 }} />
                  <Typography color={hexToRgba(tenant?.identityColor as string, 0.7)} fontWeight='bold'>
                    Subir PDF
                  </Typography>
                </Box>
              )
            }


            <input type='file' hidden ref={ref} onChange={handleFileChange} />
          </Box>
        </Grid>
      </Grid>
    </DialogDefault>
  );
};