import React, { useEffect } from 'react';
import { useSwgTapBySectionReportLazyQuery } from '../../../shared/types/generated';
import { useStore } from '@cobuildlab/react-simple-state';
import { useAuth } from '../../auth/auth-hook';
import { ApexOptions } from 'apexcharts';
import es from 'apexcharts/dist/locales/es.json';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { CARD_HEIGHT_DASHBOARD } from '../../../shared/constants';
import Chart from 'react-apexcharts';
import {
  changeDashboardDateFilterStore,
  selectedSectionStore,
} from '../dashboard-events';
import { selectedSectionStoreAction } from '../dashboard-actions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStylesCustom } from './DashboardCardItem';

export const DashboardGoogleBySectionReportBar: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [callAction, { data, loading }] = useSwgTapBySectionReportLazyQuery();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const cleanFilter = (): void => {
    handleClose();
    selectedSectionStoreAction('');
  };

  const categories = (data?.swgTapBySectionReport || []).map(
    (category) => category?.name,
  );
  useEffect(() => {
    callAction({
      variables: {
        where: {
          from,
          to,
          tenantId: tenant?.id as string,
        },
      },
    });
  }, [from, to, tenant, callAction]);

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Total',
        data: (data?.swgTapBySectionReport || []).map((d) => d?.count || 0),
      },
    ],

    options: {
      colors: [tenant?.identityColor],
      chart: {
        height: 300,
        type: 'bar',
        defaultLocale: 'es',
        locales: [es],
        events: {
          dataPointSelection(
            e: any,
            chart?: any,
            options?: { dataPointIndex: number },
          ) {
            const { section } = selectedSectionStore.get();
            if (!options) return null;
            const dataPointIndex = options?.dataPointIndex || 0;
            const selectedSection =
              categories && categories.length
                ? (categories[dataPointIndex] as string)
                : '';
            if (!section) {
              selectedSectionStoreAction(selectedSection);
            } else {
              selectedSectionStoreAction(
                selectedSection !== section ? selectedSection : '',
              );
            }
          },
        },
      },
      plotOptions: {
        bar: {
          // borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return val.toLocaleString();
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Card
      elevation={2}
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        title="Análisis por secciones"
        style={{ padding: '0px 0px' }}
        classes={{
          title: classes.title,
        }}
        action={
          <>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={cleanFilter}>Limpiar filtro</MenuItem>
            </Menu>
          </>
        }
      />
      <CardContent
        className={classes.content}
        sx={{ display: 'flex', justifyContent: { md: 'center', xs: 'start' } }}
        style={{
          position: 'relative',
          height: CARD_HEIGHT_DASHBOARD,
          maxHeight: CARD_HEIGHT_DASHBOARD,
          overflowX: 'auto',
        }}
      >
        <Box
          position={'absolute'}
          height={'92%'}
          sx={{ width: '89%', minWidth: '400px' }}
        >
          <Chart
            options={DATA.options}
            series={DATA.series}
            type="bar"
            height={'100%'}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
