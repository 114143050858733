import { createStoreAction } from '@cobuildlab/react-simple-state';
import { authStepStore, fetchAuthStore } from './auth-events';
import { AuthStep } from './auth-types';
import { User } from '../../shared/types/generated';

export const authStepAction = createStoreAction(
  authStepStore,
  (prev, form: AuthStep) => ({
    ...prev,
    form,
  }),
);

export const fetchAuthStoreAction = createStoreAction(
  fetchAuthStore,
  (prev, user: User | undefined | null) => ({
    ...prev,
    user,
  }),
);
