import { Tenant, TenantUserRoles, User } from '../../shared/types/generated';
import { TENANT_ROLES } from '../../shared/constants';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';

const ANIMAL_POLITICO_EXCLUSIVE_PERMISSIONS = [
  RBACPermissions.TENANT_COMMENTS,
  RBACPermissions.TENANT_DASHBOARD_SUBSCRIPTION,
];

const ANIMAL_POLITICO_BLOCK_PERMISSIONS = [
  RBACPermissions.TENANT_DASHBOARD_WALL_CRASHES,
];

const VANGUARDIA_BLOCK_PERMISSIONS = [
  RBACPermissions.TENANT_COMMENTS,
  RBACPermissions.TENANT_DASHBOARD_SUBSCRIPTION,
];

const RODEO_CAPITAL_PERMISSIONS = [
  RBACPermissions.TENANT_COMMENTS,
  RBACPermissions.SETTING_TENANT,
  RBACPermissions.SETTINGS,
];

const VANGUARDIA_MX_MAIN_PERMISSIONS = [
  RBACPermissions.TENANT_COMMENTS,
  RBACPermissions.SETTING_TENANT,
  RBACPermissions.SETTINGS,
];

const NOROESTE_TENANT_ID = '65b79aa3b00f0e71eb61b713';
const VANGUARDIA_MX_TENANT_ID = '65b39e5af17e852e77abc149';
const VANGUARDIA_MX_MAIN_TENANT_ID = '6678b7c2784bed7cd8bf125c';
const RODEO_CAPITAL_TENANT_ID = '661d9c3e67929c6920fe06e0';

export const checkUserRole = (
  user: User | undefined | null,
  tenant: Tenant | undefined | null,
  permission: RBACPermissions,
): boolean => {
  const tenantUser = user?.tenantUsers?.find((_tenantUser) =>
    TENANT_ROLES.includes(_tenantUser?.role as TenantUserRoles),
  );
  let role = user?.role;

  if (tenantUser) {
    role = tenantUser.role;
  }

  if (
    ANIMAL_POLITICO_EXCLUSIVE_PERMISSIONS.includes(permission) &&
    tenant &&
    tenant.id === '65f47446af45c4f2fa1d5a5c'
  )
    return !!RBACSchema.find(
      (schema) =>
        schema.Role === role && schema.Permissions.includes(permission),
    );

  if (
    ANIMAL_POLITICO_BLOCK_PERMISSIONS.includes(permission) &&
    tenant &&
    tenant.id === '65f47446af45c4f2fa1d5a5c'
  )
    return false;

  if (
    VANGUARDIA_BLOCK_PERMISSIONS.includes(permission) &&
    tenant &&
    tenant.id === VANGUARDIA_MX_TENANT_ID
  )
    return false;

  if (
    permission === RBACPermissions.TENANT_DASHBOARD_WALL_CRASHES &&
    tenant &&
    tenant.id === VANGUARDIA_MX_TENANT_ID || tenant?.id === NOROESTE_TENANT_ID
  )
    return !!RBACSchema.find(
      (schema) =>
        schema.Role === role && schema.Permissions.includes(permission),
    );

  if (tenant?.id === RODEO_CAPITAL_TENANT_ID) {
    return RODEO_CAPITAL_PERMISSIONS.includes(permission);
  }

  if (tenant?.id === VANGUARDIA_MX_MAIN_TENANT_ID) {
    return VANGUARDIA_MX_MAIN_PERMISSIONS.includes(permission);
  }

  return !!RBACSchema.find(
    (schema) => schema.Role === role && schema.Permissions.includes(permission),
  );
};
