import React, { useState } from 'react';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Avatar, Box, Card, Typography } from '@mui/material';
import {
  TenantUser,
  TenantUserRoles,
  TenantUserStatuses,
  useTenantUsersQuery,
} from '../../shared/types/generated';
import { useEvent } from '@cobuildlab/react-simple-state';
import { useAuth } from '../auth/auth-hook';
import { SearchInput } from '../../shared/components/inputs/SearchInput';
import {
  getDateByTimezone,
  sanitizeTenantUserRole,
  sanitizeTenantUserStatus,
} from '../../shared/utils';
import {
  changeTenantUserStatusStoreAction,
  updateTenantUserStoreAction,
} from '../tenant/tenant-actions';
import { ChangeTenantUserStatusDialog } from '../tenant/components/ChangeTenantUserStatusDialog';
import { UpdateTenantUserDialog } from '../tenant/components/UpdateTenantUserDialog';
import { fetchTenantEvent } from '../tenant/tenant-events';
import { useTableSort } from '../../shared/components/table/useTableSort';

type SettingUsersProps = {
  title?: string;
};

export const SettingUsers: React.FC<SettingUsersProps> = ({
  title = 'Usuarios',
}) => {
  const { tenant } = useEvent(fetchTenantEvent);
  const { tenant: currentTenant } = useAuth();
  const tenantId = tenant?.id || currentTenant?.id;
  const [count, setCount] = useState(0);
  const { sort, handleSort } = useTableSort();
  const [search, setSearch] = useState<string>();

  const [pagination, setPagination] = useState({ pageSize: 10, page: 1 });

  const { data, loading, refetch } = useTenantUsersQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        tenant: {
          id: tenantId as string,
        },
        sort,
        search,
      },
      page: pagination.page - 1,
      pageSize: pagination.pageSize,
    },
    onCompleted: (res) => {
      setCount(res?.tenantUsers?.count || 0);
      console.log('usuarios: ', res?.tenantUsers?.items);
    },
  });

  const tenantUsers = (data?.tenantUsers?.items || []) as TenantUser[];

  console.log({ tenantUsers });

  const COLUMNS: TableColumnType<TenantUser>[] = [
    {
      columnName: '',
      columnValue: null,
      columnComponent: (item: TenantUser) => (
        <Box display="flex" justifyContent="center">
          <Avatar
            src={item.user?.avatar as string}
            sx={{ width: 24, height: 24 }}
          />
        </Box>
      ),
    },
    {
      columnName: 'NOMBRE',
      columnValue: (item: TenantUser) => item.user?.name || '',
      sortableField: 'user.name',
    },
    {
      columnName: 'EMAIL',
      columnValue: (item: TenantUser) => item.user?.email || '',
      sortableField: 'user.email',
    },
    {
      columnName: 'ROL',
      columnValue: (item: TenantUser) =>
        sanitizeTenantUserRole(item.role as TenantUserRoles),
      sortableField: 'role',
    },
    {
      columnName: 'ESTADO',
      columnValue: (item: TenantUser) =>
        sanitizeTenantUserStatus(item.status as TenantUserStatuses),
      sortableField: 'status',
    },
    {
      columnName: 'ÚLTIMO LOGIN',
      columnValue: (item: TenantUser) =>
        item.user?.last_login
          ? getDateByTimezone(item.user?.last_login as string, tenant?.timezone)
          : '-',
      sortableField: 'user.last_login',
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item: TenantUser) =>
        getDateByTimezone(item.createdAt as string, tenant?.timezone),
      sortableField: 'createdAt',
    },
  ];

  return (
    <>
      <Box
        marginBottom={1}
        paddingX={1}
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{ textAlign: { xs: 'center', md: 'start' } }}
          fontSize={{ xs: '18px', md: '22px' }}
          fontWeight={700}
        >
          {title}
        </Typography>
        <SearchInput
          placeholder="BUSCAR USUARIO"
          sx={{ width: '100%', maxWidth: 400, marginBottom: '8px' }}
          onSearch={(value) => {
            setSearch(value);
          }}
        />
      </Box>
      <Card
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}
      >
        <TableDefault
          count={count}
          items={tenantUsers}
          columns={COLUMNS}
          page={pagination.page}
          pageSize={pagination.pageSize}
          loading={loading}
          messageLoading={'Cargando usuarios...'}
          actions={[
            {
              actionName: (item) =>
                item.status === TenantUserStatuses.Active
                  ? 'Desactivar'
                  : 'Activar',
              onClick: (item) => changeTenantUserStatusStoreAction(true, item),
            },
            {
              actionName: () => 'Actualizar',
              onClick: (item) => updateTenantUserStoreAction(true, item),
            },
          ]}
          showPagination
          handleSort={handleSort}
          handleChangePage={(page) => {
            if (tenantId) {
              setPagination({
                ...pagination,
                page: page + 1,
              });
            }
          }}
        />

        <ChangeTenantUserStatusDialog
          onSuccess={() =>
            refetch({
              where: {
                tenant: {
                  id: tenant?.id as string,
                },
              },
              page: pagination.page,
              pageSize: pagination.pageSize,
            })
          }
        />
        <UpdateTenantUserDialog
          onSuccess={() =>
            refetch({
              where: {
                tenant: {
                  id: tenant?.id as string,
                },
              },
              page: pagination.page,
              pageSize: pagination.pageSize,
            })
          }
        />
      </Card>
    </>
  );
};
