import React, { useState } from 'react';
import { IconButton, Paper, Popover, Tooltip, Box } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import {
  SUBSCRIPTION_PAYMENT_METHOD,
  SUBSCRIPTION_STATUSES,
} from '../../../shared/constants';
import { subscriptionTableFilterStoreAction } from '../subscription-actions';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { makeStyles } from '@mui/styles';
import { GlobalSubscriptionPaymentMethod } from '../subscription-types';

const useStyles = makeStyles({
  root: {
    minWidth: '120px!important',
  },
});

type SubscriptionFilter = {
  status: string;
  paymentMethod: string;
};

const INITIAL_VALUES = {
  paymentMethod: 'all',
  status: 'all',
};

type SubscriptionTableFilterProps = {
  paymentMethodFilters: GlobalSubscriptionPaymentMethod[]
}
export const SubscriptionTableFilter: React.FC<SubscriptionTableFilterProps> = (
  { paymentMethodFilters },
) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<SubscriptionFilter>(INITIAL_VALUES);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title='Filtrar por fecha'>
        <IconButton onClick={handleClick}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id='simple-popover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper>
          <Box p={2} width={250}>
            <Box mb={2}>
              <SelectDefault
                variant='outlined'
                options={SUBSCRIPTION_STATUSES}
                labelName='Status'
                value={filter.status}
                name='status'
                onChangeData={(name, value) =>
                  setFilter({
                    ...filter,
                    [name]: value,
                  })
                }
              />
            </Box>
            <Box mb={2}>
              <SelectDefault
                variant='outlined'
                options={[...SUBSCRIPTION_PAYMENT_METHOD,
                  ...paymentMethodFilters.map((paymentMethod) =>
                    ({ value: paymentMethod.payment_method, label: paymentMethod.payment_method || 'Sin método de pago'})),
                ]}
                labelName='Método de pago'
                value={filter.paymentMethod}
                name='paymentMethod'
                defaultValue=''
                onChangeData={(name, value) =>
                  setFilter({
                    ...filter,
                    [name]: value,
                  })
                }
              />
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <ButtonDefault
                className={classes.root}
                variant='outlined'
                onClick={() => {
                  setFilter(INITIAL_VALUES);
                  subscriptionTableFilterStoreAction('all', 'all');
                  handleClose();
                }}
              >
                Limpiar
              </ButtonDefault>
              <ButtonDefault
                className={classes.root}
                onClick={() => {
                  subscriptionTableFilterStoreAction(
                    filter.status,
                    filter.paymentMethod,
                  );
                  handleClose();
                }}
              >
                APLICAR
              </ButtonDefault>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
