import { createEvent } from '@cobuildlab/react-simple-state';
import { FetchMagazineEvent, FetchMagazinesEvent } from './magazine-types';

export const createMagazineEvent = createEvent();
export const createMagazineErrorEvent = createEvent();


export const fetchMagazinesEvent = createEvent<FetchMagazinesEvent>({
  initialValue: {
    magazines: [],
    count: 0,
  },
});
export const fetchMagazinesErrorEvent = createEvent();


export const fetchMagazineEvent = createEvent<FetchMagazineEvent>({
  initialValue: {
    magazine: undefined,
    categories: [],
  },
});

export const fetchMagazineErrorEvent = createEvent();

export const updateMagazineEvent = createEvent();
export const updateMagazineErrorEvent = createEvent();