import React from 'react';
import { SideMenu } from './SideMenu';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, Box } from '@mui/material';
import { OnlyArrow } from './OnlyArrow';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

interface StylesProps {
  drawerWidth: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#F3F5F8',
      height: '100vh',
    },
    content: {
      flexGrow: 1,
      borderRadius: 16,
      minHeight: 'calc(100vh)',
      margin: '0px auto 0px auto',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: 'auto',
    },
    children: {
      padding: '20px 20px 20px 20px',
      [theme.breakpoints?.down('sm')]: {
        padding: '10px 10px 10px 10px',
      },
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
};
export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles({ drawerWidth: 180 });
  return (
    <Box className={classes.root}>
      <OnlyArrow />
      <SideMenu />
      <LocalizationProvider dateAdapter={AdapterMoment}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.children}>{children}</div>
      </main>
      </LocalizationProvider>
    </Box>
  );
};
