import { createStore } from '@cobuildlab/react-simple-state';
import { Plan, TenantUser } from '../../shared/types/generated';

export const createPlanDialogStore = createStore<{ isOpen: boolean }>({
  initialValue: {
    isOpen: false,
  },
});

export const changePlanStatusDialogStore = createStore<{
  isOpen: boolean;
  plan: Plan | undefined;
}>({
  initialValue: {
    isOpen: false,
    plan: undefined,
  },
});

export const updatePlanDialogStore = createStore<{
  isOpen: boolean;
  plan: Plan | undefined;
}>({
  initialValue: {
    isOpen: false,
    plan: undefined,
  },
});
