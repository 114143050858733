import React, { useEffect } from 'react';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { CARD_HEIGHT_DASHBOARD, DateFilter } from '../../../shared/constants';
import { useStore } from '@cobuildlab/react-simple-state';

import { useAuth } from '../../auth/auth-hook';
import { generateCategoryMonth } from '../../../shared/utils';
import moment from 'moment';
import es from 'apexcharts/dist/locales/es.json';
import { useSwgTapByMonthReportLazyQuery } from '../../../shared/types/generated';
import { CountData } from '../dashboard-types';
import { changeDashboardDateFilterStore } from '../dashboard-events';
import { Loading } from '../../../shared/components/Loading';
import { useStylesCustom } from './DashboardCardItem';

moment.locale('es');
export const DashboardGoogleLineBar: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);

  const { tenant } = useAuth();
  const [callAction, { data, loading }] = useSwgTapByMonthReportLazyQuery({
    onError(error) {
      console.error(error.message);
    },
  });

  useEffect(() => {
    callAction({
      variables: {
        where: {
          from,
          to,
          tenantId: tenant?.id as string,
          period: filter,
        },
      },
    });
  }, [from, to, filter, tenant, callAction]);

  const categories = generateCategoryMonth(
    filter,
    from,
    to,
    (data?.swgTapByMonthReport || []) as CountData[],
    tenant,
  );

  const swgTap = categories.map((date) => {
    const swgTapReport = (data?.swgTapByMonthReport || []).find(
      (u) => u?.date === date,
    );

    if (swgTapReport) {
      return {
        count: swgTapReport.count,
        date: swgTapReport.date,
      };
    }

    return {
      count: 0,
      date,
    };
  });
  const report = swgTap.map((m, key) => {
    let add = 0;

    for (let i = 0; i < key; i++) {
      add += swgTap[i]?.count || 0;
    }

    return {
      count: (m?.count || 0) + add,
      date: m?.date,
    };
  });

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Usuarios nuevos',
        type: 'column',
        data: categories.map((category) => {
          const item = swgTap.find((r) => category === r?.date);
          if (item) return item?.count || 0;

          return 0;
        }),
      },
      {
        name: 'Usuarios registrados',
        type: 'line',
        data: categories.map((category) => {
          const item = report.find((r) => category === r.date);

          if (item) return item?.count || 0;

          return 0;
        }),
      },
    ],
    options: {
      colors: [tenant?.identityColor, 'rgba(50,50,50,.85)'],
      chart: {
        height: 300,
        type: 'line',
        defaultLocale: 'es',
        locales: [es],
      },
      stroke: {
        width: [0, 3],
      },
      markers: {
        size: 0,
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
        formatter: function (val: string) {
          return val.toLocaleString();
        },
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories: [DateFilter.TODAY, DateFilter.YESTERDAY].includes(filter)
          ? categories.map((category) => moment(category).format('HH:mm'))
          : categories,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Card
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px 16px 26px 16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        style={{ padding: '0px 0px' }}
        classes={{
          title: classes.title,
        }}
        title="Usuarios registrados"
      />
      <CardContent
        className={classes.content}
        sx={{ display: 'flex', justifyContent: { md: 'center', xs: 'start' } }}
        style={{
          position: 'relative',
          height: CARD_HEIGHT_DASHBOARD,
          maxHeight: CARD_HEIGHT_DASHBOARD,
          overflowX: 'auto',
        }}
      >
        {loading ? (
          <Box
            fontSize={32}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position={'absolute'}
            height={'94%'}
          >
            <Loading />
          </Box>
        ) : (
          <Box
            position={'absolute'}
            height={'92%'}
            sx={{ width: '89%', minWidth: '400px' }}
          >
            <Chart
              options={DATA.options}
              series={DATA.series}
              type="line"
              width={'100%'}
              height={'95%'}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
