import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../dashboard-events';
import { useGetClickedReportLazyQuery } from '../../../shared/types/generated';
import { DashboardEvents } from '../dashboard-types';
import { useAuth } from '../../auth/auth-hook';
import { Loading } from '../../../shared/components/Loading';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { fetchUserByMonth } from '../../user/user-actions';
import { winnerNotes } from '../dashboard-actions';
import { DashboardCardItem } from './DashboardCardItem';
import { calculatePercent } from '../dashboard-utils';

export const DashboardWallCrashesFunnel: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [userRegisteredCount, setUserRegisteredCount] = useState(0);
  const [winnerNoteCount, setWinnerNoteCount] = useState(0);

  const [callbackEvents, { data: events, loading }] =
    useGetClickedReportLazyQuery();

  const [callWinnerNotes, loadingWinnerNotes] = useCallAction(winnerNotes, {
    onCompleted: (res) => {
      setWinnerNoteCount(res.total);
    },
  });

  const [callFetchUser, loadingUserRegistered] = useCallAction(
    fetchUserByMonth,
    {
      onCompleted: (data) => {
        setUserRegisteredCount(
          data.reduce((acc, curr) => {
            return acc + curr.count;
          }, 0),
        );
      },
    },
  );

  useEffect(() => {
    if (tenant) {
      callbackEvents({
        variables: {
          where: {
            events: [DashboardEvents.wallCrashesContent],
            tenantId: tenant?.id,
            from,
            to,
          },
        },
      });

      callFetchUser({
        tenant,
        from,
        to,
        filter: filter,
      });

      callWinnerNotes({
        tenant,
        from,
        to,
        order: 'desc',
      });
    }
  }, [
    callbackEvents,
    from,
    tenant,
    to,
    filter,
    callFetchUser,
    callWinnerNotes,
  ]);

  const wallCrashes = events?.getClickedReport?.find(
    (data) => data?.name === DashboardEvents.wallCrashesContent,
  );

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Funnel Series',
        data: [wallCrashes?.count || 0, userRegisteredCount, winnerNoteCount],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 370,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          barHeight: '50%',
          isFunnel: true,
        },
      },
      colors: [tenant?.identityColor, 'rgba(50,50,50,.85)'],
      dataLabels: {
        enabled: true,
        formatter: function(val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val.toLocaleString();
        },
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '16px',
          colors: ['#000'],
          fontWeight: 400,
        },
      },
      xaxis: {
        categories: [
          'Choques contra el muro',
          'Usuarios registrados',
          'Notas ganadoras',
        ],
      },
    },
  };

  const DATA_PERCENT = [
    {
      title: '% CHOQUES VS USUARIOS REGISTRADOS',
      totalPercent: calculatePercent(userRegisteredCount, wallCrashes?.count).toFixed(2),
      totalNumber: ((calculatePercent(userRegisteredCount, wallCrashes?.count) / 100) * (wallCrashes?.count || 0)),

    },
    {
      title: '% DE REBOTES',
      totalPercent: (100 - calculatePercent(userRegisteredCount, wallCrashes?.count)).toFixed(2),
      totalNumber: (((100 - calculatePercent(userRegisteredCount, wallCrashes?.count)) / 100) * (wallCrashes?.count || 0)),

    },
    {
      title: '% CHOQUES VS NOTAS GANADORAS',
      totalPercent: calculatePercent(winnerNoteCount, wallCrashes?.count).toFixed(2),
      totalNumber: ((calculatePercent(winnerNoteCount, wallCrashes?.count) / 100) * (wallCrashes?.count || 0)),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card elevation={2}>
          <CardHeader title='Choques contra el muro' />
          <CardContent style={{ height: 383 }}>
            {loading || loadingUserRegistered || loadingWinnerNotes ? (
              <Box
                fontSize={32}
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='100%'
              >
                <Loading />
              </Box>
            ) : (
              <Chart
                options={DATA.options}
                series={DATA.series}
                type='bar'
                height={'100%'}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          {DATA_PERCENT.map((d) => (
            <Grid item xs={12}>
              <DashboardCardItem
                title={d.title}
                content={`${d.totalPercent} %`}
                subContent={`(${d.totalNumber.toLocaleString()})`}
                loading={loading || loadingUserRegistered || loadingWinnerNotes}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
