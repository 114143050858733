import { MagazineFrequency, MagazineStatus } from './magazine-types';


export const getFrequency = (frequency: MagazineFrequency): string => {
  switch (frequency) {
    case MagazineFrequency.Annually:
      return 'Anual';
    case MagazineFrequency.Monthly:
      return 'Mensual';
    case MagazineFrequency.Weekly:
      return 'Semanal';
    default:
      return 'Diario';
  }
};

export const getStatus = (status: MagazineStatus): string => {
  switch (status) {
    case MagazineStatus.Completed:
      return 'Completado';
    case MagazineStatus.Processing:
      return 'Procesando';
    default:
      return 'Sin procesar';
  }
};