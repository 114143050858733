import React, { useEffect, useState } from 'react';
import {
  createTenantDialogStore,
  createTenantUserInvitationDialogStore,
  fetchTenantEvent,
  updateTenantUserDialogStore,
} from '../tenant-events';
import { useEvent, useStore } from '@cobuildlab/react-simple-state';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import {
  createTenantUserInvitationDialogStoreAction,
  updateTenantUserStoreAction,
} from '../tenant-actions';
import { Grid } from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import {
  CreateTenantInput,
  CreateTenantUserInvitationInput,
  TenantUser,
  TenantUserRoles,
  useCreateTenantUserInvitationMutation,
  User,
  useUpdateTenantUserMutation,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { TENANT_USER_ROLES_OPTIONS } from '../../../shared/constants';

type UpdateTenantUserDialogProps = {
  onSuccess: () => void;
};

export const UpdateTenantUserDialog: React.FC<UpdateTenantUserDialogProps> = ({
  onSuccess,
}) => {
  const { isOpen, tenantUser } = useStore(updateTenantUserDialogStore);
  const [form, setForm] = useState<TenantUser | undefined>(undefined);
  const [updateTenantUser, { loading }] = useUpdateTenantUserMutation({
    onCompleted: () => {
      onSuccess();
      updateTenantUserStoreAction(false, undefined);
      openSnackbarAction(
        'Se ha actualizado el usuario con éxito',
        TypeMessage.SUCCESS,
      );
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar elusuario',
        TypeMessage.ERROR,
      );
    },
  });

  useEffect(() => {
    if (tenantUser) setForm(tenantUser);
  }, [tenantUser]);

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof TenantUser]: value,
    });
  };

  return (
    <DialogDefault
      title={'ACTUALIZAR USUARIO'}
      isOpen={isOpen}
      handleClose={() => updateTenantUserStoreAction(false, undefined)}
      showActions
      isLoading={loading}
      handleSuccess={() => {
        updateTenantUser({
          variables: {
            input: {
              id: form?.id,
              role: form?.role,
              user: {
                id: form?.user?.id,
                name: form?.user?.name,
              },
            },
          },
        });
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputDefault
            variant="outlined"
            labelName="Nombre"
            value={form?.user?.name}
            name="name"
            onChangeData={(field, value) => {
              setForm({
                ...form,
                user: {
                  ...(form?.user as User),
                  name: value,
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectDefault
            variant="outlined"
            labelName="Rol"
            value={form?.role}
            options={TENANT_USER_ROLES_OPTIONS}
            name="role"
            onChangeData={onChange}
          />
        </Grid>
      </Grid>
    </DialogDefault>
  );
};
