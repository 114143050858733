import { createAction, createStoreAction } from '@cobuildlab/react-simple-state';
import { createMagazineDialogStore, createMagazineFileProgressStore } from './magazine-stores';
import {
  createMagazineErrorEvent,
  createMagazineEvent, fetchMagazineErrorEvent, fetchMagazineEvent,
  fetchMagazinesErrorEvent,
  fetchMagazinesEvent, updateMagazineErrorEvent, updateMagazineEvent,
} from './magazine-events';
import axios from 'axios';
import { fetchAuthStore } from '../auth/auth-events';
import {
  CreateMagazineInput,
  FetchMagazineEvent,
  FetchMagazinesEvent,
  UpdateMagazineInput,
  WhereMagazine,
} from './magazine-types';


export const createMagazineDialogStoreAction = createStoreAction(createMagazineDialogStore, (prev, isOpen: boolean) => ({
  ...prev,
  isOpen,
}));

export const createMagazineFileProgressStoreAction = createStoreAction(createMagazineFileProgressStore, (prev, progress: number) => ({
  ...prev,
  progress,
}));

export const createMagazineAction = createAction(
  createMagazineEvent,
  createMagazineErrorEvent, async (file: File, data: CreateMagazineInput) => {
    const { tenant } = fetchAuthStore.get();
    const form = new FormData();
    if (file) {
      form.append('file', file);
      form.append('data', JSON.stringify(data));
    }

    const url = `${tenant?.webhook as string}/magazine/create`;
    await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {

        const percentProgress = Math.round(progressEvent.loaded * 100 / (progressEvent.total || 1));
        createMagazineFileProgressStoreAction(percentProgress);
      },
    });
  },
);


export const fetchMagazinesAction = createAction(
  fetchMagazinesEvent, fetchMagazinesErrorEvent, async (where: WhereMagazine) => {
    const { tenant } = fetchAuthStore.get();
    const url = `${tenant?.webhook as string}/magazine/list`;

    const response = await axios.get<FetchMagazinesEvent>(url, { params: where });

    return response.data;
  });

export const fetchMagazineAction = createAction(fetchMagazineEvent, fetchMagazineErrorEvent,
  async (id: string) => {
    const { tenant } = fetchAuthStore.get();
    const url = `${tenant?.webhook as string}/magazine/detail/${id}`;

    const response = await axios.get<FetchMagazineEvent>(url);

    return response.data;
  });

export const updateMagazineAction = createAction(
  updateMagazineEvent,
  updateMagazineErrorEvent, async (data: UpdateMagazineInput) => {
    const { tenant } = fetchAuthStore.get();
    const url = `${tenant?.webhook as string}/magazine/update`;

    const response = await axios.post<FetchMagazineEvent>(url, data);

    return response.data;
  },
);
