import React, { ReactNode } from 'react';
import { CardHeader, Divider, Card, CardContent } from '@mui/material';
import { ButtonDefault } from '../ButtonDefault';
import { makeStyles } from '@mui/styles';
import { useStylesCustom } from '../../../modules/dashboard/components/DashboardCardItem';

type CardSectionProps = {
  children?: ReactNode;
  actions?: ReactNode;
  avatar?: ReactNode;
  title?: string | ReactNode;
  subheader?: ReactNode;
  showCardHeader?: boolean;
};

export const CardSection: React.FC<CardSectionProps> = ({
  children,
  actions,
  title,
  avatar,
  subheader,
  showCardHeader = true,
}) => {
  const classes = useStylesCustom();

  return (
    <Card
      variant="outlined"
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      {showCardHeader && (
        <>
          <CardHeader
            classes={{
              title: classes.title,
            }}
            className={classes.header}
            avatar={avatar}
            action={actions}
            title={title}
            subheader={subheader}
            titleTypographyProps={{
              mb: 1,
              mr: 1,
            }}
          />
          <Divider />
        </>
      )}

      <CardContent className={classes.content}>{children}</CardContent>
    </Card>
  );
};
//className={classes.root}
