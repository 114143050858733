import React from 'react';
import { Box, Chip } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { getDateFilterText } from '../dashboard-utils';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../dashboard-events';
import { openRangePickerEvent } from '../../../shared/components/DateRangePicker';

export const DashboardGeneralDateText: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);

  return (
    <Box display="flex" alignItems="center">
      <Chip
        variant="outlined"
        icon={<DateRangeIcon fontSize="small" />}
        onClick={() => {
          openRangePickerEvent.dispatch(true);
        }}
        label={
          <Box textTransform="capitalize">
            {getDateFilterText(from, to, filter)}
          </Box>
        }
      />
    </Box>
  );
};
