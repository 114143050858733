import {
  ClickReportResponse,
  HeatMapResponse,
  Tenant,
} from '../../shared/types/generated';
import { DashboardEvents } from './dashboard-types';
import moment from 'moment';
import 'moment/locale/es';
import { DateFilter, DEFAULT_TIMEZONE } from '../../shared/constants';
import momentTimezone from 'moment-timezone';

moment.locale('es');

export const getSelectedEvents = (
  data: ClickReportResponse[],
  events: DashboardEvents[],
): ClickReportResponse[] => {
  return data.filter((x) => events.includes(x.name as DashboardEvents));
};

export const dashboardLoginCategoryName = (name: DashboardEvents): string => {
  let categoryName = '';
  switch (name) {
    case DashboardEvents.facebookClicked:
      categoryName = 'FACEBOOK';
      break;
    case DashboardEvents.gmailClicked:
      categoryName = 'GOOGLE';
      break;
    case DashboardEvents.appleClicked:
      categoryName = 'APPLE';
      break;
  }

  return categoryName;
};

const TIMES = Array.from({ length: 24 }, (_, index) => index);

export const sanitizeHeatMapData = (
  data: HeatMapResponse[],
): {
  categories: string[];
  series: {
    name: string;
    data: { x: string; y: number }[];
  }[];
} => {
  const from = moment().subtract(1, DateFilter.WEEK).toISOString();
  const categories = Array.from({ length: 8 }, (_, index) => index).map((x) => {
    return moment(from).add(x, 'day').format('YYYY-MM-DD');
  });

  const series = TIMES.map((time) => ({
    name: `${time >= 0 && time < 10 ? `0${time}` : time}:00`,
    data: categories.map((category) => {
      const headMapFind = data.find(
        (headMap) =>
          headMap.date_time?.date === category &&
          headMap.date_time?.time ===
          `${time >= 0 && time < 10 ? `0${time}` : time}`,
      );
      if (headMapFind) {
        return {
          y: headMapFind.count as number,
          x: category,
        };
      }

      return {
        x: category,
        y: 0,
      };
    }),
  }));

  return {
    categories,
    series,
  };
};

export const sanitizeCountData = (
  data: {
    count: number;
    date: string;
  }[],
  date: DateFilter,
  tenant: Tenant | undefined | null,
): {
  count: number;
  date: string;
}[] => {
  return data.map((re) => ({
    count: re.count,
    date: [DateFilter.TODAY, DateFilter.YESTERDAY].includes(date as DateFilter)
      ? momentTimezone(re.date)
        .utc(true)
        .tz(tenant?.timezone as string)
        .format('YYYY-MM-DD HH:mm')
      : re.date,
  }));
};

export const getDateFilterText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  const ranges = `( ${moment(from).utcOffset(0).format('DD-MM')} - ${moment(to).utcOffset(0).format('DD-MM')} )`;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = `Mes actual ${ranges}`;
      break;
    case DateFilter.CURRENT_WEEK:
      text = `Esta semana ${ranges}`;
      break;
    case DateFilter.LAST_WEEK:
      text = `Semana anterior ${ranges}`;
      break;
    case DateFilter.LAST_MONTH:
      text = `Mes anterior ${ranges}`;
      break;
    case DateFilter.YESTERDAY:
      text = `Ayer ${ranges}`;
      break;
    case DateFilter.CURRENT_YEAR:
      text = `Este año ${ranges}`;
      break;
    case DateFilter.DAY:
      text = `Desde ${moment(from).utcOffset(0).format('DD-MM')} hasta ${moment(to).utcOffset(0).format('DD-MM')}`;
      break;
    case DateFilter.CUSTOM:
      text = `Desde ${moment(from).utcOffset(0).format('DD-MM')} hasta ${moment(to).utcOffset(0).format('DD-MM')}`;
      break;
    default:
      text = `Hoy ${ranges}`;
      break;
  }

  return text;
};

export const getDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Usuarios registrados este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Usuarios registrados esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Usuarios registrados la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Usuarios registrados el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Usuarios registrados ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Usuarios registrados este año';
      break;
    case DateFilter.MONTH:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.DAY:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.YEAR:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Usuarios registrados hoy';
      break;
  }

  return text;
};

export const getDateFilterSectionText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Secciones este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Secciones esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Secciones la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Secciones el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Secciones ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Secciones este año';
      break;
    case DateFilter.YEAR:
      text = `Seccione desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.MONTH:
      text = `Seccione desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.DAY:
      text = `Seccione desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Secciones hoy';
      break;
  }

  return text;
};

export const getSubscriptionDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Suscripciones registradas este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Suscripciones registradas esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Suscripciones registradas la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Suscripciones registradas el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Suscripciones registradas ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Suscripciones registradas este año';
      break;
    case DateFilter.CUSTOM:
      text = `Suscripciones registradas desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Suscripciones registradas hoy';
      break;
  }

  return text;
};

export const getSubscriptionActiveDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Suscripciones activas este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Suscripciones activas esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Suscripciones activas la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Suscripciones activas el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Suscripciones activas ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Suscripciones activas este año';
      break;
    case DateFilter.CUSTOM:
      text = `Suscripciones activas desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Suscripciones activas hoy';
      break;
  }

  return text;
};

export const subscriptionStatus = (status: string): string => {
  let s: string;

  switch (status) {
    case 'INACTIVE':
      s = 'Inactivo';
      break;
    case 'CANCELLED':
      s = 'Cancelado';
      break;
    default:
      s = 'Activo';
      break;
  }

  return s;
};


export const calculatePercent = (a: number, b: number | undefined | null): number => {
  if (!b) return 0;

  return ((a * 100) / b);
};

export const getSubscriptionTotalDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Ganancias totales este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Ganancias totales esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Ganancias totales la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Ganancias totales el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Ganancias totales ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Ganancias totales este año';
      break;
    case DateFilter.DAY:
      text = `Ganancias totales desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.CUSTOM:
      text = `Ganancias totales desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Ganancias totales hoy';
      break;
  }

  return text;
};