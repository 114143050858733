import { useEffect } from 'react';
import { getUrlRedirect } from './session-utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-hook';

export const useDefaultRedirect = (): void => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (user && isAuthenticated && location.pathname === '/') {
      navigate(getUrlRedirect(user));
    }
  }, [isAuthenticated, location.pathname, navigate, user]);
};
