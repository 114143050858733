import { createStore } from '@cobuildlab/react-simple-state';

export const subscriptionTableFilterStore = createStore<{
  status: string;
  paymentMethod: string;
}>({
  initialValue: {
    status: 'all',
    paymentMethod: 'all',
  },
});
