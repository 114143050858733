import React, { useState } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { Box, Button, Typography } from '@mui/material';
import { CARD_HEIGHT_DASHBOARD } from '../../../shared/constants';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import hexToRgba from 'hex-to-rgba';
import { SwgTapByUrlDataResponseWithIdObj } from '../../analytic/analytic-types';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useBreakPoint } from '../../../shared/hooks/useBreakPoint';

type DashboardGoogleWinnerNotesPieProps = {
  data?: SwgTapByUrlDataResponseWithIdObj[];
};

export const DashboardGoogleWinnerNotesPie: React.FC<
  DashboardGoogleWinnerNotesPieProps
> = ({ data = [] }) => {
  const { isBelow } = useBreakPoint('sm');
  const { tenant } = useAuth();
  const [showPercent, setShowPercent] = useState(true);

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: data.map((d) => d.count || 0),

    options: {
      colors: [
        tenant?.identityColor,
        '#FFCC0F',
        '#000',
        'rgba(50,50,50,0.8)',
        'rgba(50,50,50,0.5)',
        '#92C5FC',
        hexToRgba(tenant?.identityColor as string, 0.75),
      ],
      chart: {
        width: 380,
        type: 'donut',
      },
      labels: data.map((d) => d.url || ''),
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      dataLabels: {
        formatter(val, opts) {
          const count = opts.w.globals.series[opts.seriesIndex];
          const value = `${parseInt(val as string).toFixed(1)}%`;
          if (showPercent) return value;
          return `${count}`;
        },
      },
      legend: {
        show: false,
      },
    },
  };

  return (
    <Box>
      <Box my={2} px={1} textAlign="end">
        <Box
          marginBottom={1}
          px={1}
          textAlign="end"
          display={'flex'}
          paddingBottom={1}
          borderBottom={'2px solid #BBBBBB'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography color={'#BBBBBB'} fontWeight={700}>
            Vista grafica
          </Typography>
          <Button
            size="small"
            variant="text"
            startIcon={
              <img src="/icon-toggle.svg" alt="clip" width={14} height={14} />
            }
            style={{
              height: 35,
              textTransform: 'inherit',
              fontWeight: 700,
              border: 'none',
            }}
            onClick={() => setShowPercent(!showPercent)}
          >
            {showPercent ? 'Mostrar cantidad' : 'Mostrar porcentaje'}
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: CARD_HEIGHT_DASHBOARD - Number(isBelow ? 30 : 0) }}>
        <Chart
          options={DATA.options}
          series={DATA.series}
          type="pie"
          width={'100%'}
          height={'100%'}
        />
      </Box>
    </Box>
  );
};
