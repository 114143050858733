import axios from 'axios';
import { REACT_APP_BACKEND_PATH } from './constants';

export const uploadFile = async (file: File): Promise<string> => {
  const form = new FormData();
  form.append('file', file);
  const response = await axios.post(`${REACT_APP_BACKEND_PATH}/upload`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};
