import React from 'react';
import { PostComment } from '../comment-types';
import { Grid, Box, Typography, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
//import { theme } from '../../../shared/css/theme';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/auth-hook';
import { capitalize } from '@mui/material';
const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0px 0px 16px 0px #00000029',
      border: '1px solid #BBBBBB',
      background: 'white',
      borderRadius: '10px',
      height: '100%',
    },
    text: {
      color: 'black',
      textDecoration: 'none !important',
      lineHeight: '21px',
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    buttonText: {
      fontSize: '12px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      marginRight: theme.spacing(0.5),
    },
    textTheme: {
      padding: '0px ',
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        padding: '0px',
      },
    },
    imageTheme: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: '200px',
        maxWidth: '200px',
      },
    },
  }),
);

type PostCommentItemProps = {
  post: PostComment;
};

export const PostCommentItem: React.FC<PostCommentItemProps> = ({ post }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { tenant } = useAuth();
  return (
    <Box component={'div'}>
      <Box className={classes.container}>
        <Grid
          spacing={{ xs: 0, sm: 1 }}
          rowSpacing={{ xs: 1, sm: 0 }}
          p={1}
          container
        >
          <Grid
            item
            xs={12}
            sm={3}
            display={'flex'}
            alignContent={'center'}
            justifyContent={'center'}
            position={'relative'}
            overflow={'hidden'}
          >
            <Box className={classes.imageTheme}>
              <img
                src={(post?.post_image || tenant?.logo) as string}
                alt="post"
                style={{
                  objectFit: 'contain',
                  maxWidth: '95%',
                  maxHeight: '95%',
                  position: 'absolute',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} className={classes.textTheme}>
            <Grid container>
              <Grid item xs={12} alignSelf={'start'} paddingBottom={1}>
                <Box
                  component={'a'}
                  className={classes.text}
                  href={post.post_url}
                  target="_blank"
                >
                  <Typography
                    height={'63px'}
                    overflow={'hidden'}
                    style={{ lineClamp: 3 }}
                    className={classes.text}
                  >
                    {capitalize(post?.post_title)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} alignSelf={'end'}>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    className={classes.iconContainer}
                    onClick={() => navigate(`/comments/${post.post_id}`)}
                  >
                    <CommentOutlinedIcon className={classes.icon} />
                    <Typography
                      className={classes.buttonText}
                    >{`(${post.comments}) comentarios`}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.iconContainer}
                    onClick={() => navigate(`/comments/${post.post_id}`)}
                  >
                    <ReplyOutlinedIcon className={classes.icon} />
                    <Typography
                      className={classes.buttonText}
                    >{`(${post.replyComments}) respondidos`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
