import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

export const TenantUserDetailsHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    console.log('TenantUserDetailsHistory: ', id);
  }, [id]);

  return <Container maxWidth={'lg'} sx={{ height: '400px' }} />;
};
