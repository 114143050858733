import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { DashboardGoogleLineBar } from './DashboardGoogleLineBar';
import { DashboardGoogleContainer } from './DashboardGoogleContainer';
import { DashboardGoogleBySectionReportBar } from './DashboardGoogleBySectionReportBar';
import { DashboardGoogleWinnerNote } from './DashboardGoogleWinnerNote';
import { DateRangePicker } from '../../../shared/components/DateRangePicker';
import { formatterDateFilter, sanitizeCustomDate } from '../../../shared/utils';
import { DateFilter } from '../../../shared/constants';
import { changeDashboardDateFilterStoreAction } from '../dashboard-actions';
import { useAuth } from '../../auth/auth-hook';
import { DashboardGoogleWinnerNoteContainer } from './DashboardGoogleWinnerNoteContainer';
import moment from 'moment/moment';
import { DashboardGeneralDateText } from './DashboardGeneralDateText';

export const DashboardGoogleTab: React.FC = () => {
  const { tenant } = useAuth();

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                Informe registro de usuarios por google one tap
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'flex-end',
                  },
                })}
              >
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {
                    const filters = formatterDateFilter(
                      range.startDate,
                      range.endDate,
                      range.key as DateFilter,
                    );

                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      filters.startDate,
                      filters.endDate,
                      dateFilter,
                    );
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <DashboardGoogleContainer />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardGoogleLineBar />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardGoogleBySectionReportBar />
          </Grid>
          <Grid item xs={12}>
            <DashboardGoogleWinnerNoteContainer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
