import React from 'react';
import { CardSection } from '../../../shared/components/card/CardSection';
import { Box, Grid, Typography } from '@mui/material';
import { MagazinePDFPage } from './MagazinePDFPage';
import { useEvent } from '@cobuildlab/react-simple-state';
import { fetchMagazineEvent } from '../magazine-events';


export const MagazinePageTab: React.FC = () => {

  const { magazine } = useEvent(fetchMagazineEvent);


  const pages = magazine?.pages || [];

  return (
    <CardSection title={
      <Typography fontWeight='bold' fontSize={20}>Páginas</Typography>
    }>
      <Box py={2}>
        <Grid container spacing={2}>
          {
            pages.map((item) => (
              <Grid item xs={3}>
                <CardSection title={`Página ${item.page}`}>
                  <MagazinePDFPage page={item} />
                </CardSection>

              </Grid>
            ))
          }

        </Grid>
      </Box>

    </CardSection>
  );
};