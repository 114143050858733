import React from 'react';
import { Avatar, Box, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStepAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { BackButton } from '../../../shared/components/BackButton';
import { getFormType, getRedirectUrl } from '../auth-utils';
import { useForm, Controller } from 'react-hook-form';
import { authForgotPasswordSchema } from '../auth-validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForgotPasswordMutation } from '../../../shared/types/generated';
import { handleFormErrors } from '../../../shared/utils/errors';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

const StyledTypographyText = styled(Typography)({
  color: '#999999',
  fontSize: 16,
});

const StyledButton = styled(ButtonDefault)({
  borderRadius: '4px!important',
  backgroundColor: 'black !important',
  border: 'none!important',
  height: '38px !important',
  color: 'white!important',
  minWidth: 200,
  '&:disabled': {
    backgroundColor: '#999999 !important',
    fontWeight: 500,
  },
});

export const AuthForgotPassword: React.FC = () => {
  const form = getFormType() as string;
  const redirectUrl = getRedirectUrl();

  const { control, formState, watch, handleSubmit } = useForm({
    resolver: yupResolver(authForgotPasswordSchema),
    defaultValues: { email: '' },
  });
  const email = watch('email');

  const [userForgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => {
      openSnackbarAction(
        'Se ha enviado un correo para restablecer la contraseña',
        TypeMessage.SUCCESS,
      );
      authStepAction(AuthStep.SIGN_IN);
    },
    onError: (data) => {
      openSnackbarAction(data.message, TypeMessage.ERROR);
    },
  });

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      userForgotPassword({
        variables: {
          email: dataForm.email,
          form,
          redirectUrl,
        },
      });
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  return (
    <Box
      component="form"
      noValidate
      onSubmit={onFormSubmit}
      sx={{
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box display={'flex'} mb={1} alignItems={'center'} width={'100%'}>
        <Avatar sx={{ mr: 1, bgcolor: 'black' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ fontFamily: 'Poppins' }}>
          Restaurar contraseña
        </Typography>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={6}
        mt={1}
        sx={{ width: '100%' }}
      >
        <Box>
          <Controller
            name={'email'}
            control={control}
            render={({ field }) => (
              <TextField
                sx={{ margin: 0, mb: 2 }}
                margin="normal"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <img
                      alt="logo"
                      width={20}
                      height={20}
                      src="/icon-email.svg"
                    />
                  ),
                }}
                variant="standard"
                id="email"
                label="Correo electrónico"
                autoComplete="email"
                error={!!formState.errors.email?.message}
                helperText={formState.errors.email?.message}
                {...field}
              />
            )}
          />
          <StyledTypographyText textAlign={'center'}>
            {`Enviaremos un mensaje al correo ${email} para restablecer
              la contraseña`}
          </StyledTypographyText>
        </Box>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <BackButton
            text="volver"
            onClick={() => authStepAction(AuthStep.SIGN_IN)}
            fullWidth
          />
          <StyledButton
            isLoading={Boolean(loading)}
            variant="contained"
            size="large"
            sx={{ fontFamily: 'Poppins' }}
            disabled={!formState.isValid}
            type="submit"
            fullWidth
          >
            Aceptar
          </StyledButton>
        </Stack>
      </Stack>
    </Box>
  );
};
