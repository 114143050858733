import React, { ReactElement } from 'react';
import {
  Box,
  Container,
  Grid,

  Tab,
  tabClasses,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../auth/auth-hook';
import { CustomHeader } from '../../../shared/components/layout/CustomHeader';
import { MAGAZINE_OPTIONS } from '../magazine-constants';

type MagazineLayoutProps = {
  children: ReactElement;
}
export const MagazineLayout: React.FC<MagazineLayoutProps> = (
  {
    children,
  }) => {

  const [currentTab, setCurrentTab] = React.useState(0);
  const navigate = useNavigate();

  const { user, tenant } = useAuth();
  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor:
        (tenant?.identityColor ?? '#000000') + '20' + '!important',
    },
  });


  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}
      >
        <Box height='100%'>
          <Grid
            container
            spacing={2}
            direction='row'
            justifyContent='center'
            alignItems='stretch'
            height='100%'
          >
            <Grid xs={12} item>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  paddingBottom: 1,
                }}
              >
                <Tabs
                  sx={{
                    padding: { sm: '0px 10px !important' },

                    ['& .MuiTabs-indicator']: {
                      display: 'none !important',
                    },
                  }}
                  value={currentTab}
                  onChange={handleChange}
                  aria-label='scrollable auto tabs example'
                  variant='scrollable'
                  allowScrollButtonsMobile
                >
                  {MAGAZINE_OPTIONS.map((route, key) => (
                    <TabItem
                      key={key}
                      label={route.name}
                      onClick={() => navigate(route.path)}
                    />
                  ))}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>{children}</Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>

  );
};