import React, { useState } from 'react';
import { Box, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& > .MuiPopover-paper': {
      background: 'rgba(50,50,50,.8)',
      color: 'white',
      maxWidth: 300,
      padding: 10,
      wordWrap: 'break-word',
    },
  },
}));

type DashboardPopoverLinkItemProps = {
  url?: string;
};
export const DashboardPopoverLinkItem: React.FC<
  DashboardPopoverLinkItemProps
> = ({ url }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  //
  return (
    <>
      <Box
        style={{
          width: 200,
          wordWrap: 'break-word',
        }}
      >
        <a
          href={url}
          target="_blank"
          style={{
            width: '100%',
            overflow: 'hidden',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#000000',
            fontWeight: 700,
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {url}
        </a>
      </Box>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={classes.root}
      >
        {url}
      </Popover>
    </>
  );
};
