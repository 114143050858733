import React from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PRIMARY_COLOR } from '../css/theme';

interface BackButtonProps extends ButtonProps {
  text?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({
  text = 'atrás',
  sx = {},
  ...rest
}) => {
  return (
    <Button
      size="small"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        textTransform: 'uppercase',
        minWidth: 110,

        borderRadius: '4px !important',
        height: '38px !important',
        color: PRIMARY_COLOR,
        ...sx,
      }}
      {...rest}
    >
      <ArrowBackIosIcon sx={{ fontSize: 14 }} />
      <Typography sx={{ fontFamily: 'Poppins' }}>{text}</Typography>
    </Button>
  );
};
