import React, { useState } from 'react';
import {
  IconButton,
  InputBase,
  inputBaseClasses,
  Paper,
  SxProps,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
type SearchInputProps = {
  onSearch: (value: string) => void;
  placeholder?: string;
  sx?: SxProps;
};
export const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  placeholder,
  sx,
}) => {
  const [search, setSearch] = useState('');
  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        ...sx,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{
          'aria-label': 'search google maps',
          style: {
            fontWeight: 700,
            color: 'black',
          },
        }}
        slotProps={{}}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch(search);
          }
        }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <PermIdentityIcon onClick={() => onSearch(search)} />
      </IconButton>
    </Paper>
  );
};
