import { TenantUserRoles, User, UserRoles } from '../../shared/types/generated';
import { TENANT_ROLES } from '../../shared/constants';

export const getUrlRedirect = (user: User): string => {
  const tenant = user.tenantUsers?.find((tenantUser) =>
    TENANT_ROLES.includes(tenantUser?.role as TenantUserRoles),
  );

  if (user.role === UserRoles.Administrator) return '/home';
  if (user.role === UserRoles.User && tenant) return '/dashboard';

  return '/settings/account';
};
