import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Container,
  Tab,
  tabClasses,
  Tabs,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { makeStyles, styled } from '@mui/styles';
import { RoleAuthorizationAdmin } from '../../rbac/RoleAuthorization';
import { SETTING_ROUTES } from '../../../shared/constants';
import { useAuth } from '../../auth/auth-hook';
import { checkUserRole } from '../../rbac/rbac-utils';
import { CustomHeader } from '../../../shared/components/layout/CustomHeader';

const useStyles = makeStyles(() => ({
  listRoot: {
    height: '100%',
    padding: 0,
  },
  listItem: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(50,50,50,.85)!important',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: 'rgba(50,50,50,.85)!important',
      color: 'white',
    },
    borderRadius: 10,
    marginBottom: 10,
    fontWeight: 'bolder',
  },
  title: {
    padding: 10,
  },
}));

type SettingLayoutProps = {
  children: ReactElement;
};

export const SettingLayoutNew: React.FC<SettingLayoutProps> = ({
  children,
}) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const currentRoute = SETTING_ROUTES.find(
    (route) => route.path === location.pathname,
  );
  const { user, tenant } = useAuth();
  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor:
        (tenant?.identityColor ?? '#000000') + '20' + '!important',
    },
  });
  useEffect(() => {
    let key = -1;
    SETTING_ROUTES.forEach((route) => {
      const path = route.path.replace(':id', id as string);
      const checkAuth = checkUserRole(user, tenant, route.permission);
      if (checkAuth) {
        key++;
      }
      if (path === location.pathname) {
        setCurrentTab(key);
      }
    });
  });
  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };
  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}
      >
        <Box height="100%">
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            height="100%"
          >
            <Grid xs={12} item>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  paddingBottom: 1,
                }}
              >
                <Tabs
                  sx={{
                    padding: { sm: '0px 10px !important' },

                    ['& .MuiTabs-indicator']: {
                      display: 'none !important',
                    },
                  }}
                  value={currentTab}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  {SETTING_ROUTES.map((route, key) => {
                    const checkAuth = checkUserRole(
                      user,
                      tenant,
                      route.permission,
                    );

                    const path = route.path.replace(':id', id as string);
                    if (checkAuth) {
                      return (
                        <TabItem
                          key={key}
                          label={route.name}
                          onClick={() => navigate(path)}
                        />
                      );
                    }
                  })}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Paper variant="outlined">
                  <Box p={2}>
                    {currentRoute?.name !== 'Usuarios' &&
                    currentRoute?.name !== 'Invitaciones' &&
                    currentRoute?.name !== 'Planes' ? (
                      <Box className={classes.title} mb={2}>
                        <Typography
                          sx={{ textAlign: { xs: 'center', md: 'start' } }}
                          fontSize={{ xs: '18px', md: '22px' }}
                          fontWeight={700}
                        >
                          {currentRoute?.name}
                        </Typography>
                      </Box>
                    ) : null}
                    <Box>{children}</Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
