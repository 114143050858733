import React from 'react';
import './App.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './shared/css/theme';
import { Snackbar } from './shared/components/snackbar/Snackbar';
import { client } from './shared/apollo';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './modules/auth/AuthProvider';


export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Snackbar />
            <Routes />
          </ThemeProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};
