import React from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import { changeTenantUserStatusStoreAction } from '../tenant-actions';
import { Box, Typography } from '@mui/material';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  TenantStatuses,
  TenantUserStatuses,
  useUpdateTenantUserMutation,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { changeTenantUserStatusDialogStore } from '../tenant-events';

type ChangeTenantStatusDialogProps = {
  onSuccess: () => void;
};
export const ChangeTenantUserStatusDialog: React.FC<
  ChangeTenantStatusDialogProps
> = ({ onSuccess }) => {
  const { isOpen, tenantUser } = useStore(changeTenantUserStatusDialogStore);

  const [updateTenantUser, { loading }] = useUpdateTenantUserMutation({
    onCompleted: () => {
      openSnackbarAction(
        `Se ha ${tenantUser?.status === TenantUserStatuses.Active ? 'desactivado' : 'activado'} el usuario exitosamente.`,
        TypeMessage.SUCCESS,
      );
      changeTenantUserStatusStoreAction(false, undefined);
      onSuccess();
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar el usuario',
        TypeMessage.ERROR,
      );
    },
  });

  return (
    <DialogDefault
      title={'ACTUALIZAR USUARIO'}
      isOpen={isOpen}
      handleClose={() => changeTenantUserStatusStoreAction(false, undefined)}
      showActions
      isLoading={loading}
      handleSuccess={() =>
        updateTenantUser({
          variables: {
            input: {
              id: tenantUser?.id as string,
              status:
                tenantUser?.status === TenantUserStatuses.Active
                  ? TenantUserStatuses.Inactive
                  : TenantUserStatuses.Active,
            },
          },
        })
      }
    >
      <Box
        height={100}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5">
          {tenantUser?.status === TenantUserStatuses.Active
            ? '¿Estas seguro que deseas desactivar al usuario?'
            : '¿Estas seguro que deseas activar al usuario?'}
        </Typography>
      </Box>
    </DialogDefault>
  );
};
