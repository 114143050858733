import React from 'react';
import { DialogDefault } from '../../../shared/components/dialog/DialogDefault';
import {
  changeTenantStatusStoreAction,
  createTenantDialogStoreAction,
  updateTenantAction,
} from '../tenant-actions';
import { Box, Grid, Typography } from '@mui/material';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeTenantStatusDialogStore } from '../tenant-events';
import { TenantStatuses } from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

type ChangeTenantStatusDialogProps = {
  onSuccess: () => void;
};
export const ChangeTenantStatusDialog: React.FC<
  ChangeTenantStatusDialogProps
> = ({ onSuccess }) => {
  const { isOpen, tenant } = useStore(changeTenantStatusDialogStore);

  const [updateTenant] = useCallAction(updateTenantAction, {
    onCompleted: () => {
      openSnackbarAction(
        `Se ha ${tenant?.status === TenantStatuses.Active ? 'desactivado' : 'activado'} tenant exitosamente.`,
        TypeMessage.SUCCESS,
      );
      changeTenantStatusStoreAction(false, undefined);
      onSuccess();
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar el tenant',
        TypeMessage.ERROR,
      );
    },
  });

  return (
    <DialogDefault
      title={'ACTUALIZAR TENANT'}
      isOpen={isOpen}
      handleClose={() => changeTenantStatusStoreAction(false, undefined)}
      showActions
      isLoading={false}
      handleSuccess={() =>
        updateTenant({
          input: {
            id: tenant?.id as string,
            status:
              tenant?.status === TenantStatuses.Active
                ? TenantStatuses.Inactive
                : TenantStatuses.Active,
          },
        })
      }
    >
      <Box
        height={100}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5">
          {tenant?.status === TenantStatuses.Active
            ? '¿Estas seguro que deseas desactivar al tenant?'
            : '¿Estas seguro que deseas activar al tenant?'}
        </Typography>
      </Box>
    </DialogDefault>
  );
};
