import { Avatar as MuiAvatar, styled } from '@mui/material';

export const AvatarSettings = styled(MuiAvatar)(({ theme }) => ({
  width: 250,
  height: 250,
  borderRadius: '50%',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  cursor: 'pointer',
  objectFit: 'contain',
  [theme.breakpoints.down('lg')]: {
    width: 150,
    height: 150,
  },
}));
