import React, { useCallback, useEffect, useState } from 'react';
import { MagazinePage } from '../magazine-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page, pdfjs } from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  GetObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3';
import axios from 'axios';
import { Loading } from '../../../shared/components/Loading';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { MagazinePDFPageError } from './MagazinePDFPageError';

interface PromiseConstructor {
  withResolvers<T>(): {
    promise: Promise<T>;
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
  };
}

const PromiseInit = Promise as unknown as PromiseConstructor;
if (typeof (PromiseInit).withResolvers === 'undefined') {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Promise.withResolvers = function() {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.Promise.withResolvers = function() {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
      return { promise, resolve, reject };
    };
  }
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

type MagazinePDFPageProps = {
  page: MagazinePage;
}

export const MagazinePDFPage: React.FC<MagazinePDFPageProps> = ({ page }) => {

  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize);


  return (
    <Box ref={setContainerRef}>
      <Document
        renderMode='canvas'
        file={page.url}
        loading={() => <Loading height={'445px'} />}
        error={() => <MagazinePDFPageError />}
        onError={(error) => {
          console.log({ error });
        }}>
        <Page pageNumber={1} width={containerWidth} height={300} />
      </Document>
    </Box>
  );

};