import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { GlobalUser, GlobalUserJoinSocialProvider } from './user-types';
import { User } from '../../shared/types/generated';

export const fetchUserCountEvent = createEvent<{ count: number }>({
  initialValue: {
    count: 0,
  },
});

export const fetchUserCountErrorEvent = createEvent<Error>();

export const fetchUserByProviderEvents = createEvent<
  { count: number; provider: string }[]
>({
  initialValue: [],
});

export const fetchUserByProviderErrorEvents = createEvent<Error>();

export const fetchGlobalUsersEvent = createEvent<{
  data: GlobalUserJoinSocialProvider[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const fetchGlobalUsersErrorEvent = createEvent();

export const updateUserEvent = createEvent<{ user: User | undefined }>({
  initialValue: {
    user: undefined,
  },
});

export const updateUserErrorEvent = createEvent<Error>();

export const createUserInvitationDialogStore = createStore<{ isOpen: boolean }>(
  {
    initialValue: {
      isOpen: false,
    },
  },
);

export const fetchUserByMonthsEvents = createEvent<
  { count: number; date: string }[]
>({
  initialValue: [],
});

export const fetchUserByMonthsErrorEvents = createEvent<Error>();

export const fetchGlobalRegisterUsersEvent = createEvent<{
  data: GlobalUserJoinSocialProvider[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const fetchGlobalRegisterUsersErrorEvent = createEvent();
