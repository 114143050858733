import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import {
  IconButton,
  InputBase,
  Link,
  Paper,
  Box,
  Stack,
  Typography,
  Container,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { GlobalUserJoinSocialProvider } from '../user/user-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { fetchGlobalUsers } from '../user/user-actions';
import { useAuth } from '../auth/auth-hook';
import { TableColumnType } from '../../shared/components/table/table-types';
import { fetchGlobalUsersEvent } from '../user/user-events';
import moment from 'moment';
import { TenantUserTableFilter } from './components/TenantUserFilter';
import { tenantUserTableFilterStore } from './tenant-stores';
import { DateRangePickerWithText } from '../../shared/components/DateRangePicker';
import { formatterDateFilter, sanitizeCustomDate } from '../../shared/utils';
import { DateFilter } from '../../shared/constants';
import { tenantUserTableFilterStoreAction } from './tenant-actions';
import { useBreakPoint } from '../../shared/hooks/useBreakPoint';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { useNavigate } from 'react-router-dom';
import { changeDashboardDateFilterStoreAction } from '../dashboard/dashboard-actions';
import { useTableSort } from '../../shared/components/table/useTableSort';

export const TenantUsers: React.FC = () => {
  const navigate = useNavigate();
  const { isBelow } = useBreakPoint('sm');
  const { data, total } = useEvent(fetchGlobalUsersEvent);
  const [callFetchUsers, loading] = useCallAction(fetchGlobalUsers);
  const { user, tenant } = useAuth();
  const { sort, handleSort } = useTableSort();
  const { provider, from, to } = useStore(tenantUserTableFilterStore);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
    search: '',
  });

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (tenant) {
      callFetchUsers({
        tenant: tenant,
        page: pagination.page - 1,
        pageSize: pagination.pageSize,
        search: pagination.search,
        provider,
        from,
        to,
        orderBy: sort,
      });
    }
  }, [
    callFetchUsers,
    tenant,
    pagination.pageSize,
    pagination.page,
    pagination.search,
    provider,
    from,
    to,
    sort,
  ]);

  const handleUserDetailsClick = (item: GlobalUserJoinSocialProvider): void => {
    navigate(`/global-users/${item.user_id}`);
  };

  const COLUMNS: TableColumnType<GlobalUserJoinSocialProvider>[] = [
    {
      columnName: 'NOMBRE',
      columnValue: null,
      columnComponent: (item) => (
        <Link component="button" onClick={() => handleUserDetailsClick(item)}>
          {item.name || ''}
        </Link>
      ),
      sortableField: 'user.name',
    },
    {
      columnName: 'EMAIL',
      columnValue: null,
      columnComponent: (item) => <Box>{item.email || ''}</Box>,
      sortableField: 'user.email',
    },
    {
      columnName: 'PROVEEDOR',
      columnValue: (item) => item.provider || '',
      sortableField: 'social_provider.provider',
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item) =>
        moment(item.created_at as string).format('YYYY-MM-DD'),
      sortableField: 'user.created_at',
    },
  ];

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          background: 'white',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width={'100%'}
          alignItems={'center'}
          justifyContent={{ xs: 'space-between' }}
        >
          <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
            Usuarios globales
          </Typography>
          <Box sx={{ width: { sx: '100%', md: 'auto' } }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              padding={'10px 0px'}
              spacing={1}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Paper
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: 400,
                  height: '30px',
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={isBelow ? 'BUSCAR' : 'BUSCAR USUARIO'}
                  inputProps={{ 'aria-label': 'search google maps' }}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  size="small"
                  onKeyUp={(e) => {
                    if (e.code === 'Enter') {
                      setPagination({
                        ...pagination,
                        page: 1,
                        search: search,
                      });
                    }
                  }}
                />

                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => {
                    setPagination({
                      ...pagination,
                      page: 1,
                      search: search,
                    });
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Box
                display="flex"
                alignItems="center"
                style={{ padding: '0px 0px 8px 0px' }}
              >
                <DateRangePickerWithText
                  onChange={(range) => {
                    const filters = formatterDateFilter(
                      range.startDate,
                      range.endDate,
                      range.key as DateFilter,
                    );
                    tenantUserTableFilterStoreAction(
                      provider,
                      filters.startDate,
                      filters.endDate,
                    );
                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      filters.startDate,
                      filters.endDate,
                      dateFilter,
                    );
                  }}
                />
                <TenantUserTableFilter />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <CardSection title={''}>
          <TableDefault
            items={data}
            count={total}
            columns={COLUMNS}
            page={pagination.page}
            pageSize={pagination.pageSize}
            loading={loading}
            messageLoading={'Cargando usuarios...'}
            handleChangePage={(page) => {
              if (tenant) {
                setPagination({
                  ...pagination,
                  page: page + 1,
                });
              }
            }}
            showPagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            handleChangeRowPerPage={(e) => {
              setPagination({
                ...pagination,
                pageSize: parseInt(e.target.value, 10),
                page: 1,
              });
            }}
            actions={[
              {
                actionName: 'Ver detalles',
                onClick: (item) => handleUserDetailsClick(item),
              },
            ]}
            handleSort={handleSort}
          />
        </CardSection>
      </Container>
    </>
  );
};
