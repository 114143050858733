import React, { ReactElement, useEffect, useState } from 'react';

import { useAuth } from '../auth/auth-hook';
import { checkUserRole } from './rbac-utils';
import { RBACPermissions } from './rbac-types';

export const ErrorDefaultView: React.FC = () => (
  <div>You dont have permission</div>
);

type RoleAuthorizationProps = {
  render: () => ReactElement<string | React.JSXElementConstructor<unknown>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  error?: () => ReactElement<
    string | React.JSXElementConstructor<unknown>
  > | null; // eslint-disable-line @typescript-eslint/no-explicit-any
  permission: RBACPermissions;
};

export const RoleAuthorizationAdmin: React.FC<RoleAuthorizationProps> = ({
  render,
  error = () => <ErrorDefaultView />,
  permission,
}) => {
  const { user, tenant } = useAuth();
  const [checkAuth, setCheckAuth] = useState<boolean>(
    checkUserRole(user, tenant, permission),
  );
  useEffect(() => {
    setCheckAuth(checkUserRole(user, tenant, permission));
  }, [permission, tenant, user]);

  if (checkAuth) return render();
  return error();
};
