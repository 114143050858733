import React, { useRef, useState } from 'react';
import { Avatar as MuiAvatar, Box, Grid, styled } from '@mui/material';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../shared/components/inputs/SelectDefault';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useAuth } from '../auth/auth-hook';
import { DEFAULT_TIMEZONE, TIMEZONES_LIST } from '../../shared/constants';
import {
  UpdateUserInput,
  useUpdateUserMutation,
} from '../../shared/types/generated';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { updateUserAction } from '../user/user-actions';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { PasswordInput } from '../../shared/components/inputs/PasswordInput';
import { AvatarSettings } from './components/SettingsStyledComponents';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

export const SettingAccount: React.FC = () => {
  const { user, tenant, refetch } = useAuth();
  const [form, setForm] = useState<UpdateUserInput>({
    id: user?.id as string,
    name: user?.name as string,
    timezone: user?.timezone as string,
  });
  const [file, setFile] = useState<File | null>(null);
  const refFile = useRef<HTMLInputElement | null>(null);

  const [updateUser, loading] = useCallAction(updateUserAction, {
    onCompleted: () => {
      openSnackbarAction(
        'Se ha actualizado el usuario exitosamente.',
        TypeMessage.SUCCESS,
      );
      refetch();
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para actualizar el usuario',
        TypeMessage.ERROR,
      );
    },
  });

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof UpdateUserInput]: value,
    });
  };

  return (
    <Box>
      <Grid container>
        <input
          type="file"
          hidden
          ref={refFile}
          onChange={(event) => {
            setFile(
              event.target.files && event.target.files.length
                ? event.target.files[0]
                : null,
            );
          }}
        />
        <Grid
          item
          xs={12}
          md={3}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <AvatarSettings
            onClick={() => refFile.current?.click()}
            src={file ? URL.createObjectURL(file) : (user?.avatar as string)}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputDefault
                variant="outlined"
                labelName="NOMBRE"
                endIcon={<PersonIcon />}
                value={form?.name}
                name="name"
                onChangeData={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDefault
                variant="outlined"
                labelName="CORREO ELECTRONICO"
                value={user?.email}
                name="email"
                endIcon={<EmailIcon />}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectDefault
                variant="outlined"
                labelName="TIMEZONES"
                value={form?.timezone || DEFAULT_TIMEZONE}
                name="timezone"
                options={TIMEZONES_LIST}
                onChangeData={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PasswordInput onChange={onChange} value={form?.password} />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="end">
                <ButtonDefault
                  isLoading={loading}
                  onClick={() => {
                    updateUser({
                      input: {
                        ...form,
                        file: file,
                      },
                    });
                  }}
                  startIcon={
                    !loading ? (
                      <img
                        src="/icon-update.svg"
                        alt="clip"
                        width={14}
                        height={14}
                      />
                    ) : null
                  }
                  style={{
                    width: 190,
                    height: 40,
                    textTransform: 'inherit',
                    fontWeight: 700,
                    background: tenant?.identityColor ?? 'black',
                  }}
                >
                  ACTUALIZAR
                </ButtonDefault>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
