import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Comment } from '../comment-types';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
});

type ShowMoreCommentsProps = {
  loading: boolean;
  count: number;
  loadMoreComments: () => void;
  comments: Comment[];
};

export const ShowMoreComments: React.FC<ShowMoreCommentsProps> = ({
  loading,
  loadMoreComments,
  count,
  comments,
}) => {
  const classes = useStyles();

  return count > comments.length ? (
    <Grid item xs={12}>
      <Paper
        elevation={1}
        className={classes.root}
        onClick={() => loadMoreComments()}
      >
        <Box p={1} textAlign="center">
          {loading ? 'Cargando...' : 'Ver más comentarios'}
        </Box>
      </Paper>
    </Grid>
  ) : null;
};
