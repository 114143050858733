import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useAuth } from '../auth/auth-hook';
import { AvatarSettings } from '../setting/components/SettingsStyledComponents';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import { PasswordInput } from '../../shared/components/inputs/PasswordInput';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { tenantUserDetailsSchema } from './tenant-validator';
import { handleFormErrors } from '../../shared/utils/errors';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { fetchGlobalUserAction, updateGlobalUser } from './tenant-actions';
import { openSnackbarAction } from '../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../shared/components/snackbar/snackbar-types';
import { SelectDefault } from '../../shared/components/inputs/SelectDefault';
import { DatePickerDefault } from '../../shared/components/inputs/DatePickerDefault';
import moment from 'moment';
import { TenantUserRoles } from '../../shared/types/generated';
import { Loading } from '../../shared/components/Loading';
import { GlobalUserJoinSocialProvider } from '../user/user-types';

export const TenantUserDetails: React.FC = () => {
  const navigate = useNavigate();
  const { tenant, user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [tenantUserDetails, setTenantUserDetails] =
    useState<GlobalUserJoinSocialProvider | null>(null);
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const isEditable = user?.tenantUsers?.find(
    (tenantUser) => tenantUser?.role === TenantUserRoles.TenantAdministrator,
  );

  const { control, formState, handleSubmit, setValue } = useForm({
    resolver: yupResolver(tenantUserDetailsSchema, { abortEarly: false }),
  });

  const [callFetch] = useCallAction(fetchGlobalUserAction, {
    onCompleted: (data) => {
      setTenantUserDetails(data);
      setValue('name', data.name);
      setValue('email', data.email);
      setValue('gender', data?.gender ?? data?.sex ?? '');
      setValue('birthday', data.birthday);
      setValue('status', data.user_status ?? data.status ?? '');
      setTimeout(() => {
        setLoadingFetch(false);
      }, 1000);
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const [callUpdateUser, loading] = useCallAction(updateGlobalUser, {
    onCompleted: () => {
      openSnackbarAction(
        'Usuario actualizado correctamente',
        TypeMessage.SUCCESS,
      );
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
      setTimeout(() => {
        navigate('/global-users');
      }, 2000);
    },
  });

  useEffect(() => {
    if (tenant && id) {
      setLoadingFetch(true);
      callFetch({
        id,
        tenant,
      });
    }
  }, [callFetch, id, tenant]);

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      callUpdateUser({
        user: { ...dataForm, email: '' },
        userId: tenantUserDetails?.id ?? '',
        tenant,
        file,
      });
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  return (
    <Container maxWidth={'lg'}>
      <Box component="form" onSubmit={onFormSubmit}>
        {loadingFetch || !tenantUserDetails ? (
          <Loading height="400px" />
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <input
                type="file"
                hidden
                disabled={!isEditable}
                ref={fileRef}
                onChange={(event) => {
                  setFile(
                    event.target.files && event.target.files.length
                      ? event.target.files[0]
                      : null,
                  );
                }}
              />
              <AvatarSettings
                sx={{ width: 150, height: 150 }}
                onClick={() => fileRef.current?.click()}
                src={
                  file
                    ? URL.createObjectURL(file)
                    : tenantUserDetails?.avatar ?? ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'name'}
                control={control}
                render={({ field }) => (
                  <InputDefault
                    variant="outlined"
                    labelName="Nombre"
                    endIcon={<PersonIcon />}
                    helperText={formState.errors.name?.message}
                    error={!!formState.errors.name?.message}
                    inputProps={{ readOnly: !isEditable }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'email'}
                control={control}
                render={({ field }) => (
                  <InputDefault
                    variant="outlined"
                    labelName="Correo electronico"
                    endIcon={<EmailIcon />}
                    helperText={formState.errors.email?.message}
                    error={!!formState.errors.email?.message}
                    inputProps={{ readOnly: !isEditable }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'gender'}
                control={control}
                render={({ field }) => (
                  <SelectDefault
                    variant="outlined"
                    labelName="Genero"
                    helperText={formState.errors.gender?.message}
                    error={!!formState.errors.gender?.message}
                    inputProps={{ readOnly: !isEditable }}
                    options={[
                      {
                        value: 'MALE',
                        label: 'MASCULINO',
                      },
                      {
                        value: 'FEMALE',
                        label: 'FEMENINO',
                      },
                      {
                        value: 'NO_APPLY',
                        label: 'NO_APPLY',
                      },
                    ]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'birthday'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePickerDefault
                    labelName="Fecha de nacimiento"
                    textFieldProps={{
                      helperText: formState.errors.birthday?.message,
                      error: !!formState.errors.birthday?.message,
                      inputProps: { readOnly: !isEditable },
                    }}
                    readOnly={!isEditable}
                    value={value !== '' ? moment(value as string) : null}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'status'}
                control={control}
                render={({ field }) => (
                  <SelectDefault
                    variant="outlined"
                    labelName="Status"
                    helperText={formState.errors.status?.message}
                    error={!!formState.errors.status?.message}
                    inputProps={{ readOnly: !isEditable }}
                    options={[
                      {
                        value: 'ACTIVE',
                        label: 'ACTIVE',
                      },
                      {
                        value: 'INACTIVE',
                        label: 'INACTIVE',
                      },
                    ]}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={'password'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PasswordInput
                    value={value}
                    onChange={(field, val) => onChange(val)}
                    helperText={formState.errors.password?.message}
                    error={!!formState.errors.password?.message}
                    inputProps={{ readOnly: !isEditable }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="end">
                {isEditable && (
                  <ButtonDefault
                    isLoading={loading}
                    type="submit"
                    startIcon={
                      !loading ? (
                        <img
                          src="/icon-update.svg"
                          alt="clip"
                          width={14}
                          height={14}
                        />
                      ) : null
                    }
                    style={{
                      width: 190,
                      height: 40,
                      textTransform: 'inherit',
                      fontWeight: 700,
                      background: tenant?.identityColor ?? 'black',
                    }}
                  >
                    ACTUALIZAR
                  </ButtonDefault>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};
