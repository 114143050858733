import React, { useState } from 'react';
import { InputDefault } from './InputDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { PRIMARY_COLOR } from '../../css/theme';
import { styled } from '@mui/material/styles';
import { OutlinedTextFieldProps } from '@mui/material';

interface PasswordInputProps
  extends Omit<OutlinedTextFieldProps, 'onChange' | 'variant'> {
  onChange?: (field: string, value: string) => void;
  value?: string | null;
}

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: PRIMARY_COLOR,
  fontSize: '20px!important',
});

export const PasswordInput: React.FC<PasswordInputProps> = ({
  onChange,
  value,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputDefault
      variant="outlined"
      labelName="CONTRASEÑA"
      value={value}
      name="password"
      onChangeData={onChange}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        style: {
          height: 48,
          borderRadius: '8px',
          background: '#F6F6F6',
        },
        endAdornment: (
          <StyledVisibilityIcon
            onClick={() => setShowPassword(!showPassword)}
          />
        ),
      }}
      {...rest}
    />
  );
};
