import React, { useState } from 'react';
import { useStore } from '@cobuildlab/react-simple-state';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import {
  CreatePlanInput,
  CreateTenantInput,
  useCreatePlanMutation,
} from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { createPlanDialogStore } from '../plan-events';
import { createPlanDialogStoreAction } from '../plan-actions';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { RichText } from '../../../shared/components/inputs/RichText';

type CreatePlanDialogProps = {
  onSuccess: () => void;
};

const DEFAULT_VALUES = {
  title: '',
  description: '',
};



export const CreatePlanDialog: React.FC<CreatePlanDialogProps> = ({
  onSuccess,
}) => {
  const { isOpen } = useStore(createPlanDialogStore);
  const [form, setForm] = useState<CreatePlanInput>(DEFAULT_VALUES);

  const [createPlan, { loading }] = useCreatePlanMutation({
    onCompleted: () => {
      onSuccess();
      createPlanDialogStoreAction(false);
      setForm(DEFAULT_VALUES);
      openSnackbarAction(
        'Se ha creado el plan exitosamente.',
        TypeMessage.SUCCESS,
      );
    },
    onError: () => {
      openSnackbarAction(
        'Tenemos problemas para crear el plan',
        TypeMessage.ERROR,
      );
    },
  });

  const onChange = (field: string, value: string): void => {
    setForm({
      ...form,
      [field as keyof CreateTenantInput]: value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => createPlanDialogStoreAction(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">CREAR PLAN</DialogTitle>
      <DialogContent style={{ width: 700 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputDefault
              variant="outlined"
              labelName="TITULO"
              value={form.title}
              name="title"
              onChangeData={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <RichText
              value={form.description as string}
              onChange={(value) => {
                setForm({
                  ...form,
                  description: value,
                });
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDefault
          color="primary"
          variant="outlined"
          onClick={() => createPlanDialogStoreAction(false)}
          style={{ width: 136 }}
        >
          Cancelar
        </ButtonDefault>
        <ButtonDefault
          onClick={() => {
            createPlan({
              variables: {
                input: {
                  ...form,
                },
              },
            });
          }}
          isLoading={loading}
          style={{ width: 136 }}
        >
          Confirmar
        </ButtonDefault>
      </DialogActions>
    </Dialog>
  );
};
