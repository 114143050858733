import React, { useState } from 'react';
import { Box, IconButton, Paper, Popover, Tooltip } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { TENANT_USER_PROVIDERS } from '../../../shared/constants';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { makeStyles } from '@mui/styles';
import { tenantUserTableFilterStoreAction } from '../tenant-actions';
import { useStore } from '@cobuildlab/react-simple-state';
import { tenantUserTableFilterStore } from '../tenant-stores';

const useStyles = makeStyles({
  root: {
    minWidth: '120px!important',
  },
});

type TenantUserFilter = {
  provider: string;
};

const INITIAL_VALUES = {
  provider: 'all',
};

export const TenantUserTableFilter: React.FC = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState<TenantUserFilter>(INITIAL_VALUES);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const { provider, from, to } = useStore(tenantUserTableFilterStore);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title="Filtrar por fecha">
        <IconButton onClick={handleClick} style={{ padding: 0  }}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Paper>
          <Box p={2} width={250}>
            <Box mb={2}>
              <SelectDefault
                variant="outlined"
                options={TENANT_USER_PROVIDERS}
                labelName="Proveedor"
                value={filter.provider}
                name="provider"
                defaultValue=""
                onChangeData={(name, value) =>
                  setFilter({
                    ...filter,
                    [name]: value,
                  })
                }
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <ButtonDefault
                className={classes.root}
                variant="outlined"
                onClick={() => {
                  setFilter(INITIAL_VALUES);
                  tenantUserTableFilterStoreAction('all', from, to);
                  handleClose();
                }}>
                Limpiar
              </ButtonDefault>
              <ButtonDefault
                className={classes.root}
                onClick={() => {
                  tenantUserTableFilterStoreAction(filter.provider, from, to);
                  handleClose();
                }}>
                APLICAR
              </ButtonDefault>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
