import { createStore } from '@cobuildlab/react-simple-state';


export const createMagazineDialogStore = createStore({
  initialValue: {
    isOpen: false,
  },
});

export const createMagazineFileProgressStore = createStore({
  initialValue: {
    progress: 0,
  },
});