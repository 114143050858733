import React from 'react';
import {
  UserInvitation,
  UserInvitationStatuses,
  UserRoles,
  useUserInvitationsQuery,
} from '../../shared/types/generated';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Box, Link, Typography } from '@mui/material';
import {
  getDateByTimezone,
  sanitizeUserInvitationStatus,
  sanitizeUserRole,
} from '../../shared/utils';
import { CardSection } from '../../shared/components/card/CardSection';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { createTenantUserInvitationDialogStoreAction } from '../tenant/tenant-actions';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { CreateUserInvitationDialog } from '../user/components/CreateUserInvitationDialog';
import { createUserInvitationDialogStoreAction } from '../user/user-actions';

export const SettingInvitations: React.FC = () => {
  const { data, loading, refetch } = useUserInvitationsQuery();

  const count = data?.userInvitations?.count || 0;

  const COLUMNS: TableColumnType<UserInvitation>[] = [
    {
      columnName: 'EMAIL',
      columnValue: null,
      columnComponent: (item: UserInvitation) => (
        <Link component="button" onClick={() => {}}>
          {item.email}
        </Link>
      ),
    },
    {
      columnName: 'ROL',
      columnValue: (item: UserInvitation) =>
        sanitizeUserRole(item.role as UserRoles),
    },
    {
      columnName: 'STATUS',
      columnValue: (item: UserInvitation) =>
        sanitizeUserInvitationStatus(item.status as UserInvitationStatuses),
    },
    {
      columnName: 'FECHA DE CREACIÓN',
      columnValue: (item: UserInvitation) =>
        getDateByTimezone(item.createdAt as string, undefined),
    },
  ];

  return (
    <>
      <Box
        paddingX={1}
        display={{ xs: 'flex' }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginBottom={{ xs: 2 }}
      >
        <Typography
          sx={{ textAlign: { xs: 'center', md: 'start' } }}
          fontSize={{ xs: '18px', md: '22px' }}
          marginBottom={{ xs: 1, md: 0 }}
          fontWeight={700}
        >
          {'Invitaciones'}
        </Typography>
        <ButtonDefault
          onClick={() => createUserInvitationDialogStoreAction(true)}
          startIcon={
            <img src="/icon-add-user.svg" alt="clip" width={14} height={14} />
          }
          style={{
            width: 195,
            height: 40,
            textTransform: 'inherit',
            fontWeight: 700,
          }}
        >
          Invitar usuario
        </ButtonDefault>
      </Box>
      <CardSection showCardHeader={false}>
        <TableDefault
          items={(data?.userInvitations?.items || []) as UserInvitation[]}
          columns={COLUMNS}
          page={1}
          pageSize={1}
          loading={loading}
          messageLoading={'Cargando invitaciones...'}
        />
        <CreateUserInvitationDialog onSuccess={() => refetch()} />
      </CardSection>
    </>
  );
};
