export type CommentUser = {
  id: string;
  name: string;
  email: string;
  avatar: string;
};

export enum CommentStatuses {
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export type Comment = {
  id: number;
  post_id: number;
  message: string;
  status: CommentStatuses;
  user: CommentUser;
  comment_id: string;
  replies: ReplyComment[];
  created_at: string;
  updated_at: string;
};
export type ReplyComment = {
  id: number;
  post_id: number;
  message: string;
  status: CommentStatuses;
  user: CommentUser;
  comment_id: string;
  created_at: string;
  updated_at: string;
};

export type PostComment = {
  id: number;
  post_id: string;
  post_title: string;
  post_type: string;
  post_content: string;
  post_date: string;
  post_image: string;
  post_name: string;
  post_url: string;
  comments: number;
  replyComments: number;
};
