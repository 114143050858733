import React, { ChangeEvent, ReactNode } from 'react';
import {
  TextField,
  OutlinedTextFieldProps,
  Box,
  Typography,
} from '@mui/material';

interface InputDefaultProps extends OutlinedTextFieldProps {
  onChangeData?: (field: string, value: string) => void;
  labelName?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export const InputDefault: React.FC<InputDefaultProps> = ({
  onChangeData,
  size = 'small',
  labelName = '',
  variant = 'outlined',
  startIcon = null,
  endIcon = null,
  ...rest
}) => {
  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    if (onChangeData) onChangeData(event.target.name, event.target.value);
  };

  return (
    <Box>
      {labelName && <Typography>{labelName}</Typography>}
      <TextField
        size={size}
        fullWidth
        onChange={onChange}
        color={'primary'}
        variant={variant}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 19 },
        }}
        inputProps={{
          style: {
            paddingLeft: 8,
          },
        }}
        InputProps={{
          style: {
            height: 48,
            borderRadius: '8px',
            background: '#F6F6F6',
          },
          startAdornment: startIcon,
          endAdornment: endIcon,
        }}
        {...rest}
      />
    </Box>
  );
};
