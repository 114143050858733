import { useContext } from 'react';

import { DefaultValuesContextType, AuthContext } from './AuthContext';

export const useAuth = (): DefaultValuesContextType => {
  const { loading, isAuthenticated, user, tenant, refetch } =
    useContext(AuthContext);

  return {
    loading,
    isAuthenticated,
    user,
    tenant,
    refetch,
  };
};
