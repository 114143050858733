import { RBACPermissions } from '../rbac/rbac-types';
import { MagazineFrequency, MagazineType } from './magazine-types';

export const MAGAZINE_OPTIONS = [
  {
    path: '/magazine',
    name: 'Revista digital',
    permission: RBACPermissions.TENANT_MAGAZINES,
  },
  {
    path: '/magazine/categories',
    name: 'Categorias',
    permission: RBACPermissions.TENANT_MAGAZINES,
  },
];

export const MAGAZINE_DETAIL_OPTIONS = [
  {
    name: 'Detalles',
    permission: RBACPermissions.TENANT_MAGAZINES,
  },
  {
    name: 'Paginas',
    permission: RBACPermissions.TENANT_MAGAZINES,
  },
];

export const MAGAZINE_TYPE_OPTIONS = [
  {
    value: MagazineType.Magazine,
    label: 'Suplemento',
  },
  {
    value:MagazineType.Newspaper,
    label: 'Periódico'
  }
];

export const MAGAZINE_FREQUENCY_OPTIONS = [
  {
    value: MagazineFrequency.Daily,
    label: 'Diario',
  },
  {
    value: MagazineFrequency.Weekly,
    label: 'Semanal',
  },
  {
    value: MagazineFrequency.Monthly,
    label: 'Mensual',
  },
  {
    value: MagazineFrequency.Annually,
    label: 'Anual',
  },
];