import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Grid, Paper, Container } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { SETTING_ROUTES } from '../../constants';
import { useAuth } from '../../../modules/auth/auth-hook';
import { checkUserRole } from '../../../modules/rbac/rbac-utils';

type SettingLayoutProps = {
  children: ReactElement;
};

export const SettingLayoutTable: React.FC<SettingLayoutProps> = ({
  children,
}) => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const { user, tenant } = useAuth();

  return (
    <>
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}
      >
        <Box height="100%">
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            height="100%"
          >
            <Grid item xs={12}>
              <Box p={{ xs: 0, md: 2 }}>{children}</Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
