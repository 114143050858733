import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { ChangeCommentStatusDialog } from './ChangeCommentStatusDialog';
import { Comment, CommentStatuses, ReplyComment } from '../comment-types';

type CommentFilterProps = {
  comment: Comment | ReplyComment;
  onSuccess: () => void;
};

export const CommentFilter: React.FC<CommentFilterProps> = ({
  comment,
  onSuccess,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState<CommentStatuses | undefined>(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
    setStatus(undefined);
  };

  const handleMenuItemClick = (s: CommentStatuses | undefined): void => {
    handleClose();
    setStatus(s);
    setOpenDialog(true);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick(CommentStatuses.APPROVED)}>
          Aprobar
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(CommentStatuses.REJECTED)}>
          Rechazar
        </MenuItem>
      </Menu>
      <ChangeCommentStatusDialog
        open={openDialog}
        comment={comment}
        status={status}
        onHandleClose={() => setOpenDialog(false)}
        onSuccess={onSuccess}
      />
    </Box>
  );
};
