import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Box } from '@mui/material';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { Plan, useUpdateTenantMutation } from '../../../shared/types/generated';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { useParams } from 'react-router-dom';
import { fetchTenantEvent } from '../tenant-events';
import { fetchTenant } from '../tenant-actions';
import { RoleAuthorizationAdmin } from '../../rbac/RoleAuthorization';
import { RBACPermissions } from '../../rbac/rbac-types';

type TenantPlanCardProps = {
  plan?: Plan | null;
}
export const TenantPlanCard: React.FC<TenantPlanCardProps> = (
  { plan },
) => {

  const { tenant } = useEvent(fetchTenantEvent);
  const [callTenant] = useCallAction(fetchTenant);
  const { id } = useParams<{ id: string }>();
  const [updateTenant, { loading }] = useUpdateTenantMutation({
    onCompleted: () => {
      callTenant(id as string);
      openSnackbarAction('Se ha actualizado el plan exitosamente.', TypeMessage.SUCCESS);
    },
    onError: () => {
      openSnackbarAction('Tenemos problemas para actualizar el plan', TypeMessage.ERROR);
    },
  });

  const selected = tenant?.plan?.id === plan?.id;


  return (
    <Card>
      <CardHeader
        title={plan?.title}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{
          align: 'center',
        }}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[700],
        }}
      />
      <CardContent style={{ height: 200 }}>
        <div dangerouslySetInnerHTML={{ __html: plan?.description as string }}></div>
      </CardContent>
      <RoleAuthorizationAdmin render={
        () => <CardActions>
          <Box width='100%' textAlign='center'>
            <ButtonDefault
              fullWidth
              variant={selected ? 'contained' : 'outlined'}
              isLoading={loading}
              onClick={() => updateTenant({
                variables: {
                  input: {
                    id: tenant?.id as string,
                    plan: {
                      id: plan?.id,
                    },
                  },

                },
              })}
            >
              {selected ? 'PLAN ACTUAL' : 'ACTIVAR'}
            </ButtonDefault>
          </Box>

        </CardActions>
      } permission={RBACPermissions.CREATE_PLAN} error={() => null} />
    </Card>
  );
};