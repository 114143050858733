import React from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { AuthSignupForm } from './components/AuthSignupForm';
import { Copyright } from '../../shared/components/Copyright';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepStore } from './auth-events';
import { AuthStep } from './auth-types';
import { AuthSignInForm } from './components/AuthSignInForm';
import { AuthForgotPassword } from './components/AuthForgotPassword';
import { AuthResetPasswordForm } from './components/AuthResetPasswordForm';
import { AuthForgotPasswordNotFound } from './components/AuthForgotPasswordNotFound';

export const Auth: React.FC = () => {
  const { form: step } = useStore(authStepStore);

  let content: JSX.Element;

  switch (step) {
    case AuthStep.SIGN_IN:
      content = <AuthSignInForm />;
      break;
    case AuthStep.SIGN_UP:
      content = <AuthSignupForm />;
      break;
    case AuthStep.FORGOT_PASSWORD:
      content = <AuthForgotPassword />;
      break;
    case AuthStep.RESET_PASSWORD:
      content = <AuthResetPasswordForm />;
      break;
    case AuthStep.FORGOT_PASSWORD_NOT_FOUND:
      content = <AuthForgotPasswordNotFound />;
      break;
    default:
      content = <AuthSignInForm />;
      break;
  }
  /** 
      height: '100vh', 
      padding:'16px',  
      backgroundImage: 'url(/auth-image.webp)',
      backgroundRepeat: 'no-repeat',
      backgroundColor: (t) =>
        t.palette.mode === 'light'
          ? t.palette.grey[50]
          : t.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',  */
  return (
    <>
      <video
        autoPlay
        muted
        loop
        style={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          zIndex: -1,
          minWidth: '100%',
          minHeight: '100%',
        }}
      >
        <source
          src="https://digitalroom.nyc3.cdn.digitaloceanspaces.com/analytics/assets-client/video-background.mp4"
          type="video/mp4"
        />
      </video>
      <Grid
        container
        justifyContent={'end'}
        alignItems={'center'}
        sx={{
          height: '100%',
          padding: '16px',
          position: 'fixed',
          right: 0,
          bottom: 0,
        }}
        component="main"
      >
        <Grid
          position={'relative'}
          item
          width={{ xs: '100%', sm: '520px' }}
          height={{ xs: '100%' }}
          component={Paper}
          borderRadius={'10px'}
          square
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            component={'div'}
            height={'80%'}
            width={'100%'}
          >
            <Box width={'100%'}>{content} </Box>
          </Box>
          <Box position={'absolute'} left={0} bottom={0} width={'100%'}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
