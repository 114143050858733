import React, { useState } from 'react';
import { DialogDefault } from '../../shared/components/dialog/DialogDefault';
import {
  TenantUserInvitation,
  TenantUserInvitationStatuses,
  useTenantUserInvitationsQuery,
} from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import { TenantUserInvitationItem } from './components/TenantUserInvitationItem';

export const TenantInvitations: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();
  const { data } = useTenantUserInvitationsQuery({
    variables: {
      where: {
        email: user?.email,
        status: TenantUserInvitationStatuses.Pending,
      },
    },
    onCompleted: ({ tenantUserInvitations }) => {
      if (tenantUserInvitations?.items?.length) setOpen(true);
    },
  });
  const invitations = (data?.tenantUserInvitations?.items ||
    []) as TenantUserInvitation[];

  return (
    <DialogDefault title={'INVITACIONES PENDIENTES'} isOpen={isOpen}>
      {invitations.map((invitation) => (
        <TenantUserInvitationItem item={invitation} />
      ))}
    </DialogDefault>
  );
};
