import React from 'react';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import { InputDefault } from '../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../shared/components/inputs/SelectDefault';
import { useAuth } from '../auth/auth-hook';
import { TenantPlanCard } from '../tenant/components/TenantPlanCard';
import { MuiColorInput } from 'mui-color-input';
import { AvatarSettings } from './components/SettingsStyledComponents';

export const SettingTenant: React.FC = () => {
  const { tenant } = useAuth();

  return (
    <Box>
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} md={3} display={'flex'} justifyContent={'center'}>
          <AvatarSettings
            style={{ borderRadius: 38 }}
            src={tenant?.logo as string}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputDefault
                disabled
                variant="outlined"
                labelName="NOMBRE"
                value={tenant?.name}
                name="name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputDefault
                disabled
                variant="outlined"
                labelName="WEBHOOK"
                value={tenant?.webhook}
                name="webhook"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectDefault
                disabled
                variant="outlined"
                labelName="URL PERMITIDAS"
                value={''}
                name="email"
                options={[]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>COLOR DE IDENTIDAD</Typography>
              <MuiColorInput
                InputProps={{
                  style: {
                    height: 48,
                    borderRadius: '8px',
                    background: '#F6F6F6',
                  },
                }}
                fullWidth
                size="small"
                disabled
                value={tenant?.identityColor || '#ffffff'}
                format="hex"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/*TODO: Remove after*/}
      <Hidden xsUp={true}>
        <Grid container>
          <Grid item xs={6}>
            <TenantPlanCard plan={tenant?.plan} />
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};
