import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CustomSizes } from './loading-types';

type LoadingProps = {
  children?: React.ReactNode;
  height?: string;
  customSize?: CustomSizes;
};
export const Loading: React.FC<LoadingProps> = ({
  children,
  height,
  customSize,
}) => {
  let loadingSize = { height: 30, width: 30 };
  switch (customSize) {
    case CustomSizes.SMALL:
      loadingSize = { height: 20, width: 20 };
      break;
    case CustomSizes.lARGE:
      loadingSize = { height: 45, width: 45 };
      break;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: height || '100vh',
        width: '100%',
      }}
    >
      {children}
      <CircularProgress style={{ ...loadingSize, padding: '5px' }} />
    </Box>
  );
};
